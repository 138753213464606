import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Divider,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const LoginScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    console.log('Screen ON_SCREEN_FOCUS Start');
    let error = null;
    try {
      if (!isFocused) {
        return;
      }
      console.log('Start ON_SCREEN_FOCUS:0 TERMINATION_CHECK');
      if (Constants['AUTHORIZATION_HEADER'] === '') {
        return;
      }
      console.log('Complete ON_SCREEN_FOCUS:0 TERMINATION_CHECK');
      console.log('Start ON_SCREEN_FOCUS:1 NAVIGATE_SCREEN');
      navigation.navigate('ChallengesListScreen');
      console.log('Complete ON_SCREEN_FOCUS:1 NAVIGATE_SCREEN');
    } catch (err) {
      console.error(err);
      error = err.message ?? err;
    }
    console.log(
      'Screen ON_SCREEN_FOCUS Complete',
      error ? { error } : 'no error'
    );
  }, [isFocused]);

  const [displayError, setDisplayError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [username, setUsername] = React.useState('');

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhiteBG },
        dimensions.width
      )}
      scrollable={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: 'center',
            paddingBottom: 36,
            paddingLeft: 36,
            paddingRight: 36,
            paddingTop: 36,
          },
          dimensions.width
        )}
        showsVerticalScrollIndicator={true}
        keyboardShouldPersistTaps={'never'}
      >
        <View>
          {/* Heading */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              style={StyleSheet.applyWidth(
                { height: 58, marginBottom: 20, width: 189 },
                dimensions.width
              )}
              resizeMode={'cover'}
              source={Images.DyAppLoginLogo}
            />
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.studilyLightBlue,
                  fontFamily: 'Poppins_700Bold',
                  fontSize: 28,
                },
                dimensions.width
              )}
            >
              {'Welcome'}
            </Text>

            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'Sign in to your account to continue'}
            </Text>
          </View>
          <Spacer top={16} right={8} bottom={16} left={8} />
          {/* Form */}
          <View>
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.studilyWhite,
                  borderBottomWidth: 1,
                  borderColor: theme.colors.studilyMilkWhite,
                  borderLeftWidth: 1,
                  borderRadius: 12,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  overflow: 'hidden',
                },
                dimensions.width
              )}
            >
              <View>
                <TextInput
                  onChangeText={newTextInputValue => {
                    try {
                      setUsername(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Studily_Light_Blue'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                  value={username}
                  placeholder={'Your email'}
                  keyboardType={'email-address'}
                />
              </View>
              <Divider
                style={StyleSheet.applyWidth({ height: 1 }, dimensions.width)}
                color={theme.colors.studilyMilkWhite}
              />
              <View>
                <TextInput
                  onChangeText={newTextInputValue => {
                    try {
                      setPassword(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.studilyLightBlue,
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                  value={password}
                  placeholder={'Your password'}
                  secureTextEntry={true}
                />
              </View>
            </View>

            <Spacer top={24} right={8} bottom={24} left={8}>
              {/* Button */}
              <View>
                {/* Touchable Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    { zIndex: 10 },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        console.log('Touchable ON_PRESS Start');
                        let error = null;
                        try {
                          console.log('Start ON_PRESS:0 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'is_refreshing',
                            value: 1,
                          });
                          console.log(
                            'Complete ON_PRESS:0 SET_GLOBAL_VARIABLE'
                          );
                          console.log('Start ON_PRESS:1 FETCH_REQUEST');
                          const loginResponseJson = await DYAPIApi.loginPOST(
                            Constants,
                            { password: password, username: username }
                          );
                          console.log('Complete ON_PRESS:1 FETCH_REQUEST', {
                            loginResponseJson,
                          });
                          console.log('Start ON_PRESS:2 EXTRACT_KEY');
                          const error = loginResponseJson?.error;
                          console.log('Complete ON_PRESS:2 EXTRACT_KEY', {
                            error,
                          });
                          console.log(
                            'Start ON_PRESS:3 SET_SCREEN_LOCAL_STATE'
                          );
                          setDisplayError(error);
                          console.log(
                            'Complete ON_PRESS:3 SET_SCREEN_LOCAL_STATE'
                          );
                          console.log('Start ON_PRESS:4 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'is_refreshing',
                            value: 0,
                          });
                          console.log(
                            'Complete ON_PRESS:4 SET_GLOBAL_VARIABLE'
                          );
                          console.log('Start ON_PRESS:5 TERMINATION_CHECK');
                          if (error) {
                            return;
                          }
                          console.log('Complete ON_PRESS:5 TERMINATION_CHECK');
                          console.log('Start ON_PRESS:6 EXTRACT_KEY');
                          const jwt_token = loginResponseJson?.jwt_token;
                          console.log('Complete ON_PRESS:6 EXTRACT_KEY', {
                            jwt_token,
                          });
                          console.log('Start ON_PRESS:7 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'AUTHORIZATION_HEADER',
                            value: 'Bearer ' + jwt_token,
                          });
                          console.log(
                            'Complete ON_PRESS:7 SET_GLOBAL_VARIABLE'
                          );
                          console.log('Start ON_PRESS:8 EXTRACT_KEY');
                          const user_id = loginResponseJson?.ID;
                          console.log('Complete ON_PRESS:8 EXTRACT_KEY', {
                            user_id,
                          });
                          console.log('Start ON_PRESS:9 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'USER_ID',
                            value: user_id,
                          });
                          console.log(
                            'Complete ON_PRESS:9 SET_GLOBAL_VARIABLE'
                          );
                          console.log('Start ON_PRESS:10 EXTRACT_KEY');
                          const user_email = loginResponseJson?.user_email;
                          console.log('Complete ON_PRESS:10 EXTRACT_KEY', {
                            user_email,
                          });
                          console.log('Start ON_PRESS:11 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'EMAIL_ID',
                            value: user_email,
                          });
                          console.log(
                            'Complete ON_PRESS:11 SET_GLOBAL_VARIABLE'
                          );
                          console.log('Start ON_PRESS:12 NAVIGATE_SCREEN');
                          navigation.navigate('ChallengesListScreen', {
                            id: Constants['USER_ID'],
                          });
                          console.log('Complete ON_PRESS:12 NAVIGATE_SCREEN');
                          console.log('Start ON_PRESS:13 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'is_refreshing',
                            value: 0,
                          });
                          console.log(
                            'Complete ON_PRESS:13 SET_GLOBAL_VARIABLE'
                          );
                        } catch (err) {
                          console.error(err);
                          error = err.message ?? err;
                        }
                        console.log(
                          'Touchable ON_PRESS Complete',
                          error ? { error } : 'no error'
                        );
                      };
                      handler();
                    }}
                  >
                    {/* CTA Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors.dyYellow,
                          borderRadius: 12,
                          flexDirection: 'row',
                          justifyContent: 'center',
                          paddingBottom: 15,
                          paddingLeft: 18,
                          paddingRight: 18,
                          paddingTop: 15,
                          zIndex: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: theme.colors['Internal_UI_Dark'],
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 15,
                            lineHeight: 21,
                            textTransform: 'none',
                          },
                          dimensions.width
                        )}
                      >
                        {'Sign In'}
                      </Text>
                    </View>
                    {/* Label Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexGrow: 1,
                          flexShrink: 0,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    />
                  </Touchable>
                </View>
              </View>
              {/* link style */}
              <View
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['link style'],
                    { justifyContent: 'center' }
                  ),
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginTop: 15 },
                    dimensions.width
                  )}
                >
                  <Link
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://members.desk-yogi.com/forgot-password/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        color: 'rgb(90, 90, 90)',
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 15,
                        marginTop: 8,
                        textDecorationLine: 'underline',
                        textTransform: 'none',
                      },
                      dimensions.width
                    )}
                    dataDetectorType={'link'}
                    selectable={true}
                    accessibilityRole={'link'}
                    accessible={true}
                    title={'Forgot Your Password?'}
                  />
                </View>
              </View>
            </Spacer>
            {/* Login */}
            <>
              {!displayError ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-end',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <>
                    {!displayError ? null : (
                      <Text
                        style={StyleSheet.applyWidth(
                          { color: theme.colors['Error Color'] },
                          dimensions.width
                        )}
                      >
                        {displayError}
                      </Text>
                    )}
                  </>
                </View>
              )}
            </>
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <>
                {!(Constants['is_refreshing'] === 1) ? null : (
                  <ActivityIndicator
                    style={StyleSheet.applyWidth(
                      { borderStyle: 'dotted', height: 36, width: 36 },
                      dimensions.width
                    )}
                    animating={true}
                    hidesWhenStopped={true}
                    size={'large'}
                    color={theme.colors['Custom #ffa500']}
                  />
                )}
              </>
            </View>

            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'OR'}
            </Text>

            <Spacer top={24} right={8} bottom={24} left={8}>
              {/* Button */}
              <View>
                {/* Touchable Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    { zIndex: 10 },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      console.log('Touchable ON_PRESS Start');
                      let error = null;
                      try {
                        console.log('Start ON_PRESS:0 NAVIGATE_SCREEN');
                        navigation.navigate('SignupScreen');
                        console.log('Complete ON_PRESS:0 NAVIGATE_SCREEN');
                      } catch (err) {
                        console.error(err);
                        error = err.message ?? err;
                      }
                      console.log(
                        'Touchable ON_PRESS Complete',
                        error ? { error } : 'no error'
                      );
                    }}
                  >
                    {/* CTA Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors.dyYellow,
                          borderRadius: 12,
                          flexDirection: 'row',
                          justifyContent: 'center',
                          paddingBottom: 15,
                          paddingLeft: 18,
                          paddingRight: 18,
                          paddingTop: 15,
                          zIndex: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: theme.colors['Internal_UI_Dark'],
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 15,
                            lineHeight: 21,
                            textTransform: 'none',
                          },
                          dimensions.width
                        )}
                      >
                        {'Sign Up'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            </Spacer>
          </View>
          {/* Link Block */}
          <View
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Link Block'], {
                justifyContent: 'center',
              }),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { backgroundColor: '"rgba(0, 0, 0, 0)"' },
                dimensions.width
              )}
            >
              <Link
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    color: 'rgb(95, 95, 95)',
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 16,
                    marginTop: 8,
                    textDecorationColor: 'rgb(0, 0, 0)',
                    textDecorationLine: 'underline',
                    textTransform: 'uppercase',
                  },
                  dimensions.width
                )}
                dataDetectorType={'link'}
                selectable={true}
                accessibilityRole={'link'}
                accessible={true}
                title={'Help'}
              />
            </View>
          </View>
          <Spacer top={16} right={8} bottom={16} left={8} />
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
