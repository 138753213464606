import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

const DeviceVariables = {
  AUTHORIZATION_HEADER: '',
  CHALLENGE_ID: '',
  EMAIL_ID: '',
  USER_ID: '',
};
const AppVariables = {
  CHALLENGEENDTYPE_ID: 'some CHALLENGEENDTYPE_ID',
  CHALLENGETYPE_ID: 'some CHALLENGETYPE_ID',
  ERROR_MESSAGE: 'some ERROR_MESSAGE',
  MEMBER_ID: 'some MEMBER_ID',
  SHOT: true,
  Visible: true,
  Visible_survey_3: true,
  catid: 'some catid',
  challenge1DataBtnCaption: 'some challenge1DataBtnCaption',
  challenge1DataBtnstatus: 'some challenge1DataBtnstatus',
  challenge1DataImage: 'some challenge1DataImage',
  challenge1DataNoDay: 'some challenge1DataNoDay',
  challenge1DataProgress: 'some challenge1DataProgress',
  challenge1DataProgressCaption: 'some challenge1DataProgressCaption',
  challenge1DataTitle: 'some challenge1DataTitle',
  challenge1id: 'some challenge1id',
  challenge2DataBtnCaption: 'some challenge2DataBtnCaption',
  challenge2DataBtnstatus: 'some challenge2DataBtnstatus',
  challenge2DataImage: 'some challenge2DataImage',
  challenge2DataNoDay: 'some challenge2DataNoDay',
  challenge2DataProgress: 'some challenge2DataProgress',
  challenge2DataProgressCaption: 'some challenge2DataProgressCaption',
  challenge2DataTitle: 'some challenge2DataTitle',
  challenge2id: 'some challenge2id',
  challenge3DataBtnCaption: 'some challenge3DataBtnCaption',
  challenge3DataBtnstatus: 'some challenge3DataBtnstatus',
  challenge3DataImage: 'some challenge3DataImage',
  challenge3DataNoDay: 'some challenge3DataNoDay',
  challenge3DataProgress: 'some challenge3DataProgress',
  challenge3DataProgressCaption: 'some challenge3DataProgressCaption',
  challenge3DataTitle: 'some challenge3DataTitle',
  challenge3id: 'some challenge3id',
  challengeDetailDataProgressCaption1:
    'some challengeDetailDataProgressCaption1',
  challengeDetailDataProgressCaption2:
    'some challengeDetailDataProgressCaption2',
  challengeDetailDataProgressCaption3:
    'some challengeDetailDataProgressCaption3',
  challenges: [
    {
      id: 'some id',
      image: 'some image',
      title: 'some title',
      status: 'some status',
      content: 'some content',
      noofday: 'some noofday',
      progress: 'some progress',
      btnstatus: 123,
      btnCaption: 'some btnCaption',
      progressCaption: 'some progressCaption',
    },
  ],
  challengewistiamediaid: 'some challengewistiamediaid',
  ending_survey_status: 'some ending_survey_status',
  fullscreen: 123,
  is_refreshing: 'some is_refreshing',
  progress: 123,
  signup_error: 'some signup_error',
  signup_url: 'some signup_url',
  starting_survey_status: 'some starting_survey_status',
  survery_end_url: 'some survery_end_url',
  survery_url: 'some survery_url',
  survey_end_status: 'some survey_end_status',
  survey_start_status: 'some survey_start_status',
  survey_status: 'some survey_status',
  survey_status_check: 'some survey_status_check',
  test: 'some test',
  wistiamediaid: 'some wistiamediaid',
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const entries = await AsyncStorage.multiGet(Object.keys(DeviceVariables));

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key, value]) => [
      key,
      value ? tryParseJson(value) : DeviceVariables[key],
    ]);

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
