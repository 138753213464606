import React from 'react';
import ConfettiCannon from 'react-native-confetti-cannon';
import * as GlobalVariableContext from './config/GlobalVariableContext';
export { ConfettiCannon };
export const Confetti = () => {
  const variables = GlobalVariableContext.useValues();
  return (
    <>
      {variables.SHOT && (
        <ConfettiCannon
          count={500}
          origin={{ x: -10, y: 0 }}
          fadeOut={true}
          autoStartDelay={3000}
          explosionSpeed={100}
          fallSpeed={5000}
        />
      )}
    </>
  );
};
