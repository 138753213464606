import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  IconButton,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  Spacer,
  StarRating,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  Platform,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const FeedbackScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShowVideo(0);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [email, setEmail] = React.useState('');
  const [feedbackResponse, setFeedbackResponse] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [rating, setRating] = React.useState(0);
  const [responseError, setResponseError] = React.useState('');
  const [responseSuccess, setResponseSuccess] = React.useState('');
  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={true}>
      {/* Menu */}
      <>
        {!showVideo ? null : (
          <Surface
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Drawer'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  top: '10%',
                },
                dimensions.width
              )}
            >
              {/* Challenges */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ChallengesListScreen', {
                          id: props.route?.params?.user_id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Ionicons/trophy-outline'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Challenges'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* About */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('AboutScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/infocirlceo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'About'}
                  </Text>
                </View>
              </Touchable>
              {/* Help */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/questioncircleo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Help'}
                  </Text>
                </View>
              </Touchable>
              {/* FAQ */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FAQScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Octicons/tasklist'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'FAQ'}
                  </Text>
                </View>
              </Touchable>
              {/* Feedback */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FeedbackScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Feather/message-square'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Feedback'}
                  </Text>
                </View>
              </Touchable>
              {/* Member Dashboard */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('MemberDashboardScreen', {
                          user_id: props.route?.params?.user_id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Feather/settings'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Member Dashboard'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Terms */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('TermsScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Ionicons/ios-document-text-outline'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Terms'}
                  </Text>
                </View>
              </Touchable>
              {/* Login */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] === '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Login'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Logout */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Logout'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
            </View>
          </Surface>
        )}
      </>
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        <>
          {/* Head Wrap */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors.divider,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 20,
                paddingBottom: 15,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 15,
              },
              dimensions.width
            )}
          >
            {/* Logo */}
            <View>
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('LoginScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Image
                  style={StyleSheet.applyWidth(
                    { height: 42, width: 66 },
                    dimensions.width
                  )}
                  resizeMode={'cover'}
                  source={Images.DyAppLogo}
                />
              </Touchable>
            </View>
            {/* Main Menu */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row' },
                dimensions.width
              )}
            >
              <IconButton
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { marginRight: 15 },
                  dimensions.width
                )}
                color={theme.colors['Custom #101010']}
                icon={'AntDesign/questioncircleo'}
                size={28}
              />
              <Checkbox
                onPress={newCheckboxValue => {
                  try {
                    setShowVideo(newCheckboxValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                status={showVideo}
                uncheckedIcon={'Entypo/menu'}
                color={theme.colors['Custom #101010']}
                uncheckedColor={theme.colors['Custom #101010']}
                checkedIcon={'Feather/x'}
                size={32}
              />
            </View>
          </View>
        </>
        <DYAPIApi.FetchFeedbackPOST>
          {({ loading, error, data, refetchFeedback }) => {
            const fetchData = data;
            if (!fetchData || loading) {
              return <ActivityIndicator />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center' }}>
                  There was a problem fetching this data
                </Text>
              );
            }

            return (
              <>
                <>
                  {!fetchData?.content ? null : (
                    <View>
                      {/* Heading */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingLeft: 16, paddingRight: 16 },
                          dimensions.width
                        )}
                      >
                        {/* Primary */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 20,
                              marginBottom: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.heading}
                        </Text>
                        {/* Secondary */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Poppins_400Regular',
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.content}
                        </Text>
                        <Spacer right={0} left={0} bottom={10} top={10} />
                        {/* Feedback Form Block */}
                        <View>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.studilyWhite,
                                borderBottomWidth: 1,
                                borderColor: theme.colors.studilyMilkWhite,
                                borderLeftWidth: 1,
                                borderRadius: 12,
                                borderRightWidth: 1,
                                borderTopWidth: 1,
                                overflow: 'hidden',
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setName(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.studilyLightBlue,
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                                placeholder={'Name'}
                              />
                            </View>
                            <Divider
                              style={StyleSheet.applyWidth(
                                { height: 1 },
                                dimensions.width
                              )}
                              color={theme.colors.studilyMilkWhite}
                            />
                            <View>
                              <TextInput
                                onChangeText={newTextInputValue => {
                                  try {
                                    setEmail(newTextInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Studily_Light_Blue'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                                placeholder={'Email'}
                                keyboardType={'email-address'}
                              />
                            </View>
                            <Divider
                              style={StyleSheet.applyWidth(
                                { height: 1 },
                                dimensions.width
                              )}
                              color={theme.colors.studilyMilkWhite}
                            />
                            <View>
                              <TextInput
                                onChangeText={newTextAreaValue => {
                                  try {
                                    setMessage(newTextAreaValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Studily_Light_Blue'],
                                    fontFamily: 'Poppins_500Medium',
                                    fontSize: 16,
                                    minHeight: 150,
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                                placeholder={'Message'}
                                changeTextDelay={500}
                                textAlignVertical={'top'}
                                multiline={true}
                                numberOfLines={4}
                              />
                            </View>
                            <Divider
                              style={StyleSheet.applyWidth(
                                { height: 1 },
                                dimensions.width
                              )}
                              color={theme.colors.studilyMilkWhite}
                            />
                            {/* Question Rating Block */}
                            <View>
                              <View
                                style={StyleSheet.applyWidth(
                                  { paddingLeft: 16, paddingRight: 16 },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text'],
                                      {
                                        fontFamily: 'Poppins_500Medium',
                                        fontSize: 16,
                                        marginTop: 10,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Rate your experience'}
                                </Text>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      justifyContent: 'center',
                                      marginTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <StarRating
                                    onPress={newStarRatingValue => {
                                      try {
                                        setRating(newStarRatingValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    rating={rating}
                                    maxStars={5}
                                    isEditable={true}
                                    activeColor={theme.colors['Dy - Yellow']}
                                    starSize={60}
                                    inactiveColor={theme.colors['Divider']}
                                  />
                                </View>
                              </View>
                            </View>
                            <Divider
                              style={StyleSheet.applyWidth(
                                { height: 1 },
                                dimensions.width
                              )}
                              color={theme.colors.studilyMilkWhite}
                            />
                          </View>
                          <Spacer top={8} right={8} bottom={8} left={8} />
                          <>
                            {!(Constants['is_refreshing'] === 1) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'center' },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!(
                                    Constants['is_refreshing'] === 1
                                  ) ? null : (
                                    <ActivityIndicator
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderStyle: 'dotted',
                                          height: 36,
                                          width: 36,
                                        },
                                        dimensions.width
                                      )}
                                      animating={true}
                                      hidesWhenStopped={true}
                                      size={'large'}
                                      color={theme.colors['Custom #ffa500']}
                                    />
                                  )}
                                </>
                              </View>
                            )}
                          </>
                          {/* Success Message */}
                          <>
                            {!responseSuccess ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'flex-end',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* success */}
                                <>
                                  {!responseSuccess ? null : (
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        { color: theme.colors['Green BG'] },
                                        dimensions.width
                                      )}
                                    >
                                      {responseSuccess}
                                    </Text>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                          {/* Error Message */}
                          <>
                            {!responseError ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'flex-end',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* error */}
                                <>
                                  {!responseError ? null : (
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        { color: theme.colors['Error Color'] },
                                        dimensions.width
                                      )}
                                    >
                                      {responseError}
                                    </Text>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                          <Spacer top={24} bottom={24} left={0} right={0}>
                            {/* Button */}
                            <View>
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    console.log('Button ON_PRESS Start');
                                    let error = null;
                                    try {
                                      console.log(
                                        'Start ON_PRESS:0 SET_GLOBAL_VARIABLE'
                                      );
                                      setGlobalVariableValue({
                                        key: 'is_refreshing',
                                        value: 1,
                                      });
                                      console.log(
                                        'Complete ON_PRESS:0 SET_GLOBAL_VARIABLE'
                                      );
                                      console.log(
                                        'Start ON_PRESS:1 FETCH_REQUEST'
                                      );
                                      const contactFormResult =
                                        await DYAPIApi.feedbackContactFormPOST(
                                          Constants,
                                          {
                                            email: email,
                                            isandroid:
                                              Platform.OS === 'android',
                                            isios: Platform.OS === 'ios',
                                            message: message,
                                            name: name,
                                            rating: rating,
                                          }
                                        );
                                      console.log(
                                        'Complete ON_PRESS:1 FETCH_REQUEST',
                                        { contactFormResult }
                                      );
                                      console.log(
                                        'Start ON_PRESS:2 EXTRACT_KEY'
                                      );
                                      const resultResponseSuccess =
                                        contactFormResult?.success;
                                      console.log(
                                        'Complete ON_PRESS:2 EXTRACT_KEY',
                                        { resultResponseSuccess }
                                      );
                                      console.log(
                                        'Start ON_PRESS:3 SET_SCREEN_LOCAL_STATE'
                                      );
                                      setResponseSuccess(resultResponseSuccess);
                                      console.log(
                                        'Complete ON_PRESS:3 SET_SCREEN_LOCAL_STATE'
                                      );
                                      console.log(
                                        'Start ON_PRESS:4 EXTRACT_KEY'
                                      );
                                      const resultResponseError =
                                        contactFormResult?.error;
                                      console.log(
                                        'Complete ON_PRESS:4 EXTRACT_KEY',
                                        { resultResponseError }
                                      );
                                      console.log(
                                        'Start ON_PRESS:5 SET_SCREEN_LOCAL_STATE'
                                      );
                                      setResponseError(resultResponseError);
                                      console.log(
                                        'Complete ON_PRESS:5 SET_SCREEN_LOCAL_STATE'
                                      );
                                      console.log(
                                        'Start ON_PRESS:6 SET_GLOBAL_VARIABLE'
                                      );
                                      setGlobalVariableValue({
                                        key: 'is_refreshing',
                                        value: 0,
                                      });
                                      console.log(
                                        'Complete ON_PRESS:6 SET_GLOBAL_VARIABLE'
                                      );
                                    } catch (err) {
                                      console.error(err);
                                      error = err.message ?? err;
                                    }
                                    console.log(
                                      'Button ON_PRESS Complete',
                                      error ? { error } : 'no error'
                                    );
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      theme.colors['Dy - Yellow'],
                                    borderRadius: 8,
                                    color: 'rgb(0,0,0)',
                                    fontFamily: 'Poppins_700Bold',
                                    textAlign: 'center',
                                  },
                                  dimensions.width
                                )}
                                title={'Send Feedback'}
                              />
                            </View>
                          </Spacer>
                        </View>
                      </View>
                    </View>
                  )}
                </>
                <>
                  {!Constants['AUTHORIZATION_HEADER'] ? null : (
                    <Button
                      onPress={() => {
                        try {
                          navigation.navigate('ChallengesListScreen', {
                            id: props.route?.params?.user_id ?? '',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['DY - Cyan'],
                          borderRadius: 8,
                          color: 'rgb(0,0,0)',
                          fontFamily: 'Poppins_700Bold',
                          marginLeft: 16,
                          marginRight: 16,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={`${fetchData?.btnCaption}`}
                    />
                  )}
                </>
              </>
            );
          }}
        </DYAPIApi.FetchFeedbackPOST>
        <Spacer top={8} right={8} bottom={8} left={8} />
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(FeedbackScreen);
