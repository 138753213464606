import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    'App Green': 'rgb(0, 204, 154)',
    'BG Gray': 'rgba(225, 218, 218, 0.15)',
    Background: 'rgba(251, 252, 253, 1)',
    'Custom #0000ff': 'rgb(0, 0, 255)',
    'Custom #002b4b': 'rgb(0, 43, 75)',
    'Custom #00374b': 'rgb(0, 55, 75)',
    'Custom #06303f': 'rgb(6, 48, 63)',
    'Custom #101010': 'rgb(16, 16, 16)',
    'Custom #14495c': 'rgb(20, 73, 92)',
    'Custom #222222': 'rgb(34, 34, 34)',
    'Custom #329fb3': 'rgb(50, 159, 179)',
    'Custom #333333': 'rgb(51, 51, 51)',
    'Custom #5f5a53': 'rgb(95, 90, 83)',
    'Custom #6174f6': 'rgb(97, 116, 246)',
    'Custom #666666': 'rgb(102, 102, 102)',
    'Custom #8a847d': 'rgb(138, 132, 125)',
    'Custom #999999': 'rgb(153, 153, 153)',
    'Custom #a0abf9': 'rgb(160, 171, 249)',
    'Custom #aaa7a3': 'rgb(170, 167, 163)',
    'Custom #acacac': 'rgb(172, 172, 172)',
    'Custom #ccd1fa': 'rgb(204, 209, 250)',
    'Custom #cfcfcf': 'rgb(207, 207, 207)',
    'Custom #d3536d': 'rgb(211, 83, 109)',
    'Custom #d8d8d8': 'rgb(216, 216, 216)',
    'Custom #dadada': 'rgb(218, 218, 218)',
    'Custom #dbdbdb': 'rgb(219, 219, 219)',
    'Custom #dddddd': 'rgb(221, 221, 221)',
    'Custom #dfdfdf': 'rgb(223, 223, 223)',
    'Custom #e9ecfc': 'rgb(233, 236, 252)',
    'Custom #efefef': 'rgb(239, 239, 239)',
    'Custom #f06454': 'rgb(240, 100, 84)',
    'Custom #f1f3f6': 'rgb(241, 243, 246)',
    'Custom #f5f7f8': 'rgb(245, 247, 248)',
    'Custom #fcfccf': 'rgb(252, 252, 207)',
    'Custom #fcfcfc': 'rgb(252, 252, 252)',
    'Custom #fdfdfd': 'rgb(253, 253, 253)',
    'Custom #ff0000': 'rgb(255, 0, 0)',
    'Custom #ff5f00': 'rgb(255, 95, 0)',
    'Custom #ffa500': 'rgb(255, 165, 0)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    'Custom Color': 'rgb(211, 211, 211)',
    'Custom Color_10': 'rgb(88, 115, 238)',
    'Custom Color_11': 'rgb(243, 102, 102)',
    'Custom Color_12': 'rgb(86, 198, 237)',
    'Custom Color_13': 'rgb(242, 164, 93)',
    'Custom Color_14': 'rgb(108, 108, 108)',
    'Custom Color_15': 'rgb(253, 253, 253)',
    'Custom Color_16': 'rgb(156, 164, 171)',
    'Custom Color_17': 'rgb(189, 189, 189)',
    'Custom Color_2': 'rgb(248, 248, 248)',
    'Custom Color_3': 'rgb(254, 76, 66)',
    'Custom Color_4': 'rgb(249, 158, 85)',
    'Custom Color_5': 'rgb(255, 222, 89)',
    'Custom Color_6': 'rgb(181, 217, 85)',
    'Custom Color_7': 'rgb(121, 191, 92)',
    'Custom Color_8': 'rgb(255, 0, 0)',
    'Custom Color_9': 'rgb(76, 217, 100)',
    'Cyan - Light': 'rgb(220, 251, 253)',
    'DY - Cyan': 'rgb(107, 186, 217)',
    Divider: 'rgba(234, 237, 242, 1)',
    'Dy - Yellow': 'rgb(255, 174, 2)',
    Error: 'rgba(255, 69, 100, 1)',
    'Error Color': 'rgb(205, 35, 19)',
    'GetFit Orange': 'rgb(254, 151, 15)',
    'Green BG': 'rgba(0, 204, 154, 0.21)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    Internal_Background: 'rgb(160, 171, 249)',
    Internal_Barely_White: 'rgb(253, 253, 253)',
    Internal_Blonde_White: 'rgb(223, 223, 223)',
    Internal_Blue: 'rgb(0, 0, 255)',
    Internal_Border: 'rgb(218, 218, 218)',
    Internal_Bronzed_Gray: 'rgb(153, 153, 153)',
    Internal_Egg_White: 'rgb(252, 252, 252)',
    Internal_Ever_Blue_White: 'rgb(233, 236, 252)',
    Internal_Hot_Red: 'rgb(255, 0, 0)',
    Internal_Light_Background: 'rgb(241, 243, 246)',
    Internal_Light_Gray: 'rgb(207, 207, 207)',
    Internal_Light_Icon_BG: 'rgb(204, 209, 250)',
    Internal_Light_Stone: 'rgb(221, 221, 221)',
    Internal_Light_White: 'rgb(239, 239, 239)',
    Internal_Orange_Secondary_Alt: 'rgb(255, 95, 0)',
    Internal_Primary_Bold: 'rgb(16, 16, 16)',
    Internal_Primary_Color: 'rgb(97, 116, 246)',
    Internal_Primary_Dark: 'rgb(51, 51, 51)',
    Internal_Secondary_Dark: 'rgb(102, 102, 102)',
    Internal_Soft_Snow_White: 'rgb(245, 247, 248)',
    Internal_UI_Dark: 'rgb(34, 34, 34)',
    Internal_White: 'rgb(255, 255, 255)',
    Internal_Yellow: 'rgb(252, 252, 207)',
    Internal_Yellow_Secondary: 'rgb(255, 165, 0)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    Medium: 'rgba(70, 78, 88, 1)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    NFT_TIME_Black: 'rgb(0, 0, 0)',
    NFT_TIME_Blue: 'rgb(33, 158, 188)',
    NFT_TIME_Border: 'rgb(153, 153, 153)',
    NFT_TIME_Cyan_White: 'rgb(233, 236, 239)',
    NFT_TIME_Dark_Gray: 'rgb(64, 72, 82)',
    NFT_TIME_Dark_White: 'rgb(241, 241, 241)',
    NFT_TIME_Divider_Dark: 'rgb(151, 151, 151)',
    NFT_TIME_Gray: 'rgb(111, 111, 111)',
    NFT_TIME_Icons: 'rgb(86, 86, 86)',
    NFT_TIME_Light_Blue: 'rgb(142, 202, 230)',
    NFT_TIME_Light_Gray: 'rgb(223, 223, 223)',
    NFT_TIME_Lime_Green: 'rgb(221, 242, 71)',
    NFT_TIME_Primary_Black: 'rgb(16, 16, 1)',
    NFT_TIME_Red: 'rgb(255, 0, 0)',
    NFT_TIME_Stone: 'rgb(207, 207, 207)',
    NFT_TIME_Stone_Gray: 'rgb(183, 183, 183)',
    NFT_Time_Dark_Mode_Link: 'rgb(252, 252, 252)',
    NFT_Time_Green: 'rgb(0, 128, 0)',
    NFT_Time_Hot_Blue: 'rgb(0, 0, 255)',
    NFT_Time_Mac_Gray: 'rgb(128, 128, 128)',
    NFT_Time_Secondary: 'rgb(71, 129, 242)',
    NFT_Time_UI_Black: 'rgb(16, 16, 16)',
    NFT_White_v2: 'rgb(255, 255, 255)',
    Opacity_Blue_for_Button: 'rgba(85, 108, 246, 0.05)',
    Option_Selected_Color: 'rgb(97, 214, 204)',
    Overlay: 'rgba(0, 0, 0, 0.39)',
    Overlay_Style_2: 'rgba(0, 0, 0, 0.61)',
    People_Bit_Light_Brown: 'rgb(138, 132, 125)',
    Peoplebit_Dark_Blue: 'rgb(0, 43, 75)',
    Peoplebit_Dark_Emerald_Green: 'rgb(20, 73, 92)',
    Peoplebit_Earthy_Brown: 'rgb(95, 90, 83)',
    Peoplebit_Light_Brown: 'rgb(170, 167, 163)',
    Peoplebit_Light_Gray: 'rgb(219, 219, 219)',
    Peoplebit_Light_Stone_Gray: 'rgb(216, 216, 216)',
    Peoplebit_Ocean_Blue: 'rgb(50, 159, 179)',
    Peoplebit_Orange: 'rgb(240, 100, 84)',
    Peoplebit_Salmon_Red: 'rgb(211, 83, 109)',
    Peoplebit_Sapphire: 'rgb(0, 55, 75)',
    Peoplebit_Stone_Gray: 'rgb(172, 172, 172)',
    Peoplebit_Turquoise: 'rgb(6, 48, 63)',
    Peoplebit_White: 'rgb(255, 255, 255)',
    Primary: 'rgba(90, 69, 255, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
    Settings_Icon_BG_Color: 'rgb(175, 185, 249)',
    ShopAppBlue: 'rgb(14, 134, 212)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    Studily_Blue_Night: 'rgb(80, 118, 198)',
    Studily_Bright_Blue: 'rgb(86, 184, 244)',
    Studily_Dark_Primary: 'rgb(17, 17, 17)',
    Studily_Dark_UI: 'rgb(17, 17, 17)',
    Studily_Emerald: 'rgb(126, 209, 172)',
    Studily_Forrest_Shade: 'rgb(126, 209, 172)',
    Studily_Gray_Machine: 'rgb(133, 141, 152)',
    Studily_Lavender_Purple: 'rgb(208, 215, 250)',
    Studily_Light_Blue: 'rgb(55, 66, 83)',
    Studily_Light_Navy_Blue: 'rgb(56, 67, 84)',
    Studily_Light_Navy_Secondary: 'rgb(80, 118, 198)',
    Studily_Milk_White: 'rgb(236, 240, 246)',
    Studily_Mint_Green: 'rgb(97, 214, 204)',
    Studily_Opacity_25: 'rgba(0, 0, 0, 0.25)',
    Studily_Primary: 'rgb(85, 108, 246)',
    Studily_Primary_UI_Dark: 'rgb(55, 66, 83)',
    Studily_Purple_Light: 'rgb(208, 215, 250)',
    Studily_Secondary_UI: 'rgb(133, 141, 152)',
    Studily_Silver_White: 'rgb(236, 240, 246)',
    Studily_Sky_Blue_Primary: 'rgb(86, 184, 244)',
    Studily_Slate_Blue_Dark: 'rgb(56, 67, 84)',
    Studily_Snow_White: 'rgb(230, 235, 244)',
    Studily_Washed_Lavender_White: 'rgb(227, 231, 249)',
    Studily_White: 'rgb(255, 255, 255)',
    Studily_White_BG: 'rgb(243, 245, 249)',
    Studily_White_Shade_1: 'rgb(227, 231, 249)',
    Studily_White_Shade_2: 'rgb(230, 235, 244)',
    Studily_White_Shade_3: 'rgb(231, 235, 241)',
    Studily_White_Shade_5: 'rgb(243, 245, 249)',
    Surface: 'rgba(255, 255, 255, 1)',
    Switch_Color_for_Settings_Page: 'rgb(96, 212, 204)',
    Tab_Divider: 'rgb(231, 235, 241)',
    TextPlaceholder: 'rgb(136, 144, 153)',
    ViewBG: 'rgba(152, 153, 156, 0.25)',
    White: 'rgb(255, 255, 255)',
    appGreen: 'rgb(0, 204, 154)',
    bGGray: 'rgba(225, 218, 218, 0.15)',
    background: 'rgba(251, 252, 253, 1)',
    custom_rgb0_0_255: 'rgb(0, 0, 255)',
    custom_rgb0_43_75: 'rgb(0, 43, 75)',
    custom_rgb0_55_75: 'rgb(0, 55, 75)',
    custom_rgb102_102_102: 'rgb(102, 102, 102)',
    custom_rgb108_108_108: 'rgb(108, 108, 108)',
    custom_rgb121_191_92: 'rgb(121, 191, 92)',
    custom_rgb138_132_125: 'rgb(138, 132, 125)',
    custom_rgb153_153_153: 'rgb(153, 153, 153)',
    custom_rgb156_164_171: 'rgb(156, 164, 171)',
    custom_rgb160_171_249: 'rgb(160, 171, 249)',
    custom_rgb16_16_16: 'rgb(16, 16, 16)',
    custom_rgb170_167_163: 'rgb(170, 167, 163)',
    custom_rgb172_172_172: 'rgb(172, 172, 172)',
    custom_rgb181_217_85: 'rgb(181, 217, 85)',
    custom_rgb189_189_189: 'rgb(189, 189, 189)',
    custom_rgb204_209_250: 'rgb(204, 209, 250)',
    custom_rgb207_207_207: 'rgb(207, 207, 207)',
    custom_rgb20_73_92: 'rgb(20, 73, 92)',
    custom_rgb211_211_211: 'rgb(211, 211, 211)',
    custom_rgb211_83_109: 'rgb(211, 83, 109)',
    custom_rgb216_216_216: 'rgb(216, 216, 216)',
    custom_rgb218_218_218: 'rgb(218, 218, 218)',
    custom_rgb219_219_219: 'rgb(219, 219, 219)',
    custom_rgb221_221_221: 'rgb(221, 221, 221)',
    custom_rgb223_223_223: 'rgb(223, 223, 223)',
    custom_rgb233_236_252: 'rgb(233, 236, 252)',
    custom_rgb239_239_239: 'rgb(239, 239, 239)',
    custom_rgb240_100_84: 'rgb(240, 100, 84)',
    custom_rgb241_243_246: 'rgb(241, 243, 246)',
    custom_rgb242_164_93: 'rgb(242, 164, 93)',
    custom_rgb243_102_102: 'rgb(243, 102, 102)',
    custom_rgb245_247_248: 'rgb(245, 247, 248)',
    custom_rgb248_248_248: 'rgb(248, 248, 248)',
    custom_rgb249_158_85: 'rgb(249, 158, 85)',
    custom_rgb252_252_207: 'rgb(252, 252, 207)',
    custom_rgb252_252_252: 'rgb(252, 252, 252)',
    custom_rgb253_253_253: 'rgb(253, 253, 253)',
    custom_rgb254_76_66: 'rgb(254, 76, 66)',
    custom_rgb255_0_0: 'rgb(255, 0, 0)',
    custom_rgb255_165_0: 'rgb(255, 165, 0)',
    custom_rgb255_222_89: 'rgb(255, 222, 89)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    custom_rgb255_95_0: 'rgb(255, 95, 0)',
    custom_rgb34_34_34: 'rgb(34, 34, 34)',
    custom_rgb50_159_179: 'rgb(50, 159, 179)',
    custom_rgb51_51_51: 'rgb(51, 51, 51)',
    custom_rgb6_48_63: 'rgb(6, 48, 63)',
    custom_rgb76_217_100: 'rgb(76, 217, 100)',
    custom_rgb86_198_237: 'rgb(86, 198, 237)',
    custom_rgb88_115_238: 'rgb(88, 115, 238)',
    custom_rgb95_90_83: 'rgb(95, 90, 83)',
    custom_rgb97_116_246: 'rgb(97, 116, 246)',
    cyanLight: 'rgb(220, 251, 253)',
    dYCyan: 'rgb(107, 186, 217)',
    divider: 'rgba(234, 237, 242, 1)',
    dyYellow: 'rgb(255, 174, 2)',
    error: 'rgba(255, 69, 100, 1)',
    errorColor: 'rgb(205, 35, 19)',
    getFitOrange: 'rgb(254, 151, 15)',
    greenBG: 'rgba(0, 204, 154, 0.21)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    internalBackground: 'rgb(160, 171, 249)',
    internalBarelyWhite: 'rgb(253, 253, 253)',
    internalBlondeWhite: 'rgb(223, 223, 223)',
    internalBlue: 'rgb(0, 0, 255)',
    internalBorder: 'rgb(218, 218, 218)',
    internalBronzedGray: 'rgb(153, 153, 153)',
    internalEggWhite: 'rgb(252, 252, 252)',
    internalEverBlueWhite: 'rgb(233, 236, 252)',
    internalHotRed: 'rgb(255, 0, 0)',
    internalLightBackground: 'rgb(241, 243, 246)',
    internalLightGray: 'rgb(207, 207, 207)',
    internalLightIconBG: 'rgb(204, 209, 250)',
    internalLightStone: 'rgb(221, 221, 221)',
    internalLightWhite: 'rgb(239, 239, 239)',
    internalOrangeSecondaryAlt: 'rgb(255, 95, 0)',
    internalPrimaryBold: 'rgb(16, 16, 16)',
    internalPrimaryColor: 'rgb(97, 116, 246)',
    internalPrimaryDark: 'rgb(51, 51, 51)',
    internalSecondaryDark: 'rgb(102, 102, 102)',
    internalSoftSnowWhite: 'rgb(245, 247, 248)',
    internalUIDark: 'rgb(34, 34, 34)',
    internalWhite: 'rgb(255, 255, 255)',
    internalYellow: 'rgb(252, 252, 207)',
    internalYellowSecondary: 'rgb(255, 165, 0)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    nFTTIMEBlack: 'rgb(0, 0, 0)',
    nFTTIMEBlue: 'rgb(33, 158, 188)',
    nFTTIMEBorder: 'rgb(153, 153, 153)',
    nFTTIMECyanWhite: 'rgb(233, 236, 239)',
    nFTTIMEDarkGray: 'rgb(64, 72, 82)',
    nFTTIMEDarkWhite: 'rgb(241, 241, 241)',
    nFTTIMEDividerDark: 'rgb(151, 151, 151)',
    nFTTIMEGray: 'rgb(111, 111, 111)',
    nFTTIMEIcons: 'rgb(86, 86, 86)',
    nFTTIMELightBlue: 'rgb(142, 202, 230)',
    nFTTIMELightGray: 'rgb(223, 223, 223)',
    nFTTIMELimeGreen: 'rgb(221, 242, 71)',
    nFTTIMEPrimaryBlack: 'rgb(16, 16, 1)',
    nFTTIMERed: 'rgb(255, 0, 0)',
    nFTTIMEStone: 'rgb(207, 207, 207)',
    nFTTIMEStoneGray: 'rgb(183, 183, 183)',
    nFTTimeDarkModeLink: 'rgb(252, 252, 252)',
    nFTTimeGreen: 'rgb(0, 128, 0)',
    nFTTimeHotBlue: 'rgb(0, 0, 255)',
    nFTTimeMacGray: 'rgb(128, 128, 128)',
    nFTTimeSecondary: 'rgb(71, 129, 242)',
    nFTTimeUIBlack: 'rgb(16, 16, 16)',
    nFTWhiteV2: 'rgb(255, 255, 255)',
    opacityBlueForButton: 'rgba(85, 108, 246, 0.05)',
    optionSelectedColor: 'rgb(97, 214, 204)',
    overlay: 'rgba(0, 0, 0, 0.39)',
    overlayStyle2: 'rgba(0, 0, 0, 0.61)',
    peopleBitLightBrown: 'rgb(138, 132, 125)',
    peoplebitDarkBlue: 'rgb(0, 43, 75)',
    peoplebitDarkEmeraldGreen: 'rgb(20, 73, 92)',
    peoplebitEarthyBrown: 'rgb(95, 90, 83)',
    peoplebitLightBrown: 'rgb(170, 167, 163)',
    peoplebitLightGray: 'rgb(219, 219, 219)',
    peoplebitLightStoneGray: 'rgb(216, 216, 216)',
    peoplebitOceanBlue: 'rgb(50, 159, 179)',
    peoplebitOrange: 'rgb(240, 100, 84)',
    peoplebitSalmonRed: 'rgb(211, 83, 109)',
    peoplebitSapphire: 'rgb(0, 55, 75)',
    peoplebitStoneGray: 'rgb(172, 172, 172)',
    peoplebitTurquoise: 'rgb(6, 48, 63)',
    peoplebitWhite: 'rgb(255, 255, 255)',
    primary: 'rgba(90, 69, 255, 1)',
    secondary: 'rgba(59, 201, 234, 1)',
    settingsIconBGColor: 'rgb(175, 185, 249)',
    shopAppBlue: 'rgb(14, 134, 212)',
    strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    studilyBlueNight: 'rgb(80, 118, 198)',
    studilyBrightBlue: 'rgb(86, 184, 244)',
    studilyDarkPrimary: 'rgb(17, 17, 17)',
    studilyDarkUI: 'rgb(17, 17, 17)',
    studilyEmerald: 'rgb(126, 209, 172)',
    studilyForrestShade: 'rgb(126, 209, 172)',
    studilyGrayMachine: 'rgb(133, 141, 152)',
    studilyLavenderPurple: 'rgb(208, 215, 250)',
    studilyLightBlue: 'rgb(55, 66, 83)',
    studilyLightNavyBlue: 'rgb(56, 67, 84)',
    studilyLightNavySecondary: 'rgb(80, 118, 198)',
    studilyMilkWhite: 'rgb(236, 240, 246)',
    studilyMintGreen: 'rgb(97, 214, 204)',
    studilyOpacity25: 'rgba(0, 0, 0, 0.25)',
    studilyPrimary: 'rgb(85, 108, 246)',
    studilyPrimaryUIDark: 'rgb(55, 66, 83)',
    studilyPurpleLight: 'rgb(208, 215, 250)',
    studilySecondaryUI: 'rgb(133, 141, 152)',
    studilySilverWhite: 'rgb(236, 240, 246)',
    studilySkyBluePrimary: 'rgb(86, 184, 244)',
    studilySlateBlueDark: 'rgb(56, 67, 84)',
    studilySnowWhite: 'rgb(230, 235, 244)',
    studilyWashedLavenderWhite: 'rgb(227, 231, 249)',
    studilyWhite: 'rgb(255, 255, 255)',
    studilyWhiteBG: 'rgb(243, 245, 249)',
    studilyWhiteShade1: 'rgb(227, 231, 249)',
    studilyWhiteShade2: 'rgb(230, 235, 244)',
    studilyWhiteShade3: 'rgb(231, 235, 241)',
    studilyWhiteShade5: 'rgb(243, 245, 249)',
    surface: 'rgba(255, 255, 255, 1)',
    switchColorForSettingsPage: 'rgb(96, 212, 204)',
    tabDivider: 'rgb(231, 235, 241)',
    'text placeholder': 'rgb(136, 144, 153)',
    textPlaceholder: 'rgb(136, 144, 153)',
    viewBG: 'rgba(152, 153, 156, 0.25)',
    white: 'rgb(255, 255, 255)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
  },
};
