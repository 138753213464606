import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({ 'Activity Indicator': { height: 36, width: 36 } });

export const ViewStyles = theme =>
  StyleSheet.create({
    'Activity Indicator 2': { alignItems: 'center' },
    'Link Block': {
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    'Link Block 2': {
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    'Rating Block': {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 8,
    },
    'link style': {
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    'link style 2': {
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      backgroundColor: theme.colors.primary,
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
  });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    Drawer: {
      backgroundColor: theme.colors['Surface'],
      flex: 2,
      height: '100%',
      minHeight: 40,
      position: 'absolute',
      top: 0,
      width: '80%',
      zIndex: 5,
    },
    Surface: { minHeight: 40 },
  });

export const ImageStyles = theme =>
  StyleSheet.create({ Image: { height: 100, width: 100 } });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({ 'Image Background': { height: '100%', width: '100%' } });

export const FetchStyles = theme =>
  StyleSheet.create({
    'New Header': { minHeight: 40 },
    'New Header 10': { minHeight: 40 },
    'New Header 11': { minHeight: 40 },
    'New Header 12': { minHeight: 40 },
    'New Header 13': { minHeight: 40 },
    'New Header 14': { minHeight: 40 },
    'New Header 2': { minHeight: 40 },
    'New Header 3': { minHeight: 40 },
    'New Header 4': { minHeight: 40 },
    'New Header 5': { minHeight: 40 },
    'New Header 6': { minHeight: 40 },
    'New Header 7': { minHeight: 40 },
    'New Header 8': { minHeight: 40 },
    'New Header 9': { minHeight: 40 },
  });

export const TextStyles = theme =>
  StyleSheet.create({ Text: { color: theme.colors.strong } });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Area': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });

export const VideoPlayerStyles = theme => StyleSheet.create({});
