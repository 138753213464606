import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View, useWindowDimensions } from 'react-native';
import { Fetch } from 'react-request';

const SurveyEndThankYouScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const challengeRedirect = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if (challenges.btnCaption === '2') {
      props.navigation.navigate('Terms');
    } else {
      props.navigation.navigate('Sign up');
    }
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShowVideo(0);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [menuTab1, setMenuTab1] = React.useState(true);
  const [menuTab2, setMenuTab2] = React.useState(false);
  const [menuTab3, setMenuTab3] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Menu */}
      <>
        {!showVideo ? null : (
          <Surface
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Drawer'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  top: '10%',
                },
                dimensions.width
              )}
            >
              {/* Challenges */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ChallengesListScreen', {
                          id: props.route?.params?.user_id ?? 19296,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Ionicons/trophy-outline'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Challenges'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* About */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('AboutScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/infocirlceo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'About'}
                  </Text>
                </View>
              </Touchable>
              {/* Help */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/questioncircleo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Help'}
                  </Text>
                </View>
              </Touchable>
              {/* FAQ */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FAQScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Octicons/tasklist'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'FAQ'}
                  </Text>
                </View>
              </Touchable>
              {/* Feedback */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FeedbackScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Feather/message-square'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Feedback'}
                  </Text>
                </View>
              </Touchable>
              {/* Member Dashboard */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('MemberDashboardScreen', {
                          user_id: props.route?.params?.user_id ?? 19296,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Feather/settings'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Member Dashboard'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Terms */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('TermsScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Ionicons/ios-document-text-outline'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Terms'}
                  </Text>
                </View>
              </Touchable>
              {/* Login */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] === '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Login'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Logout */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Logout'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
            </View>
          </Surface>
        )}
      </>
      <>
        {/* Head Wrap */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors.divider,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
              paddingBottom: 15,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Logo */}
          <View>
            <Touchable>
              <Image
                style={StyleSheet.applyWidth(
                  { height: 42, width: 66 },
                  dimensions.width
                )}
                resizeMode={'cover'}
                source={Images.DyAppLogo}
              />
            </Touchable>
          </View>
          {/* Main Menu */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('HelpScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginRight: 15 },
                dimensions.width
              )}
              color={theme.colors['Custom #101010']}
              icon={'AntDesign/questioncircleo'}
              size={28}
            />
            <Checkbox
              onPress={newCheckboxValue => {
                try {
                  setShowVideo(newCheckboxValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              status={showVideo}
              uncheckedIcon={'Entypo/menu'}
              color={theme.colors['Custom #101010']}
              uncheckedColor={theme.colors['Custom #101010']}
              checkedIcon={'Feather/x'}
              size={32}
            />
          </View>
        </View>
      </>
      <View
        style={StyleSheet.applyWidth(
          {
            marginBottom: 20,
            marginTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: 16, marginTop: 16 },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              { color: theme.colors.strong, fontFamily: 'Poppins_400Regular' },
              dimensions.width
            )}
          >
            {
              'Thank you for completing the survey. We have added a new challenge for you to participate.'
            }
          </Text>
          <Button
            onPress={() => {
              try {
                navigation.navigate('ChallengesListScreen', {
                  id: props.route?.params?.user_id ?? 19296,
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['DY - Cyan'],
                borderRadius: 8,
                color: 'rgb(0,0,0)',
                fontFamily: 'Poppins_700Bold',
                marginBottom: 16,
                marginLeft: 16,
                marginRight: 16,
                marginTop: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Go To My Challenges'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SurveyEndThankYouScreen);
