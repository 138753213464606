import React from 'react';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useWindowDimensions } from 'react-native';

const HomeStartedDesignScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const myNavigateFunction = Variables => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    navigation.navigate('Survey Start');
    if (Variables.category_id == 1) {
      // alert(Variables.category_id);
      navigation.navigate('SurveyStart');
    }
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const challengesData = await DYAPIApi.newHomePageGET(Constants, {
          user_id: Constants['USER_ID'],
        });
        const challenge1DataTitle = challengesData?.videos[0].title;
        setGlobalVariableValue({
          key: 'challenge1DataTitle',
          value: challenge1DataTitle,
        });
        const challenge1DataImage = challengesData?.videos[0].image;
        setGlobalVariableValue({
          key: 'challenge1DataImage',
          value: challenge1DataImage,
        });
        const challenge1DataNoDay = challengesData?.videos[0].noofday;
        setGlobalVariableValue({
          key: 'challenge1DataNoDay',
          value: challenge1DataNoDay,
        });
        const challenge1DataProgress = challengesData?.videos[0].progress;
        setGlobalVariableValue({
          key: 'challenge1DataProgress',
          value: challenge1DataProgress,
        });
        const challenge1DataProgressCaption =
          challengesData?.videos[0].progressCaption;
        setGlobalVariableValue({
          key: 'challenge1DataProgressCaption',
          value: challenge1DataProgressCaption,
        });
        const challenge1DataBtnstatus = challengesData?.videos[0].btnstatus;
        setGlobalVariableValue({
          key: 'challenge1DataBtnstatus',
          value: challenge1DataBtnstatus,
        });
        const challenge1DataBtnCaption = challengesData?.videos[0].btnCaption;
        setGlobalVariableValue({
          key: 'challenge1DataBtnCaption',
          value: challenge1DataBtnCaption,
        });
        const challenge2DataTitle = challengesData?.videos[1].title;
        setGlobalVariableValue({
          key: 'challenge2DataTitle',
          value: challenge2DataTitle,
        });
        const challenge2DataImage = challengesData?.videos[1].image;
        setGlobalVariableValue({
          key: 'challenge2DataImage',
          value: challenge2DataImage,
        });
        const challenge2DataNoDay = challengesData?.videos[1].noofday;
        setGlobalVariableValue({
          key: 'challenge2DataNoDay',
          value: challenge2DataNoDay,
        });
        const challenge2DataProgress = challengesData?.videos[1].progress;
        setGlobalVariableValue({
          key: 'challenge2DataProgress',
          value: challenge2DataProgress,
        });
        setGlobalVariableValue({
          key: 'challenge2DataBtnCaption',
          value: challenge2DataBtnCaption,
        });
        const challenge2DataBtnCaption = challengesData?.videos[1].btnCaption;
        const challenge2DataBtnstatus = challengesData?.videos[1].btnstatus;
        const challenge3DataTitle = challengesData?.videos[2].title;
        setGlobalVariableValue({
          key: 'challenge2DataBtnstatus',
          value: challenge2DataBtnstatus,
        });
        setGlobalVariableValue({
          key: 'challenge3DataTitle',
          value: challenge3DataTitle,
        });
        const challenge3DataImage = challengesData?.videos[2].image;
        setGlobalVariableValue({
          key: 'challenge3DataImage',
          value: challenge3DataImage,
        });
        const challenge3DataNoDay = challengesData?.videos[2].noofday;
        setGlobalVariableValue({
          key: 'challenge3DataNoDay',
          value: challenge3DataNoDay,
        });
        const challenge3DataProgress = challengesData?.videos[2].progress;
        setGlobalVariableValue({
          key: 'challenge3DataProgress',
          value: challenge3DataProgress,
        });
        const challenge3DataBtnCaption = challengesData?.videos[2].btnCaption;
        setGlobalVariableValue({
          key: 'challenge3DataBtnCaption',
          value: challenge3DataBtnCaption,
        });
        const challenge3DataBtnstatus = challengesData?.videos[2].btnstatus;
        setGlobalVariableValue({
          key: 'challenge3DataBtnstatus',
          value: challenge3DataBtnstatus,
        });
        const challenge1id = challengesData?.videos[0].id;
        setGlobalVariableValue({
          key: 'challenge1id',
          value: challenge1id,
        });
        const challenge2id = challengesData?.videos[1].id;
        setGlobalVariableValue({
          key: 'challenge2id',
          value: challenge2id,
        });
        const challenge3id = challengesData?.videos[2].id;
        setGlobalVariableValue({
          key: 'challenge3id',
          value: challenge3id,
        });
        console.log(challengesData);
        if (Constants['AUTHORIZATION_HEADER']) {
          return;
        }
        navigation.navigate('LoginScreen');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [showNav, setShowNav] = React.useState(false);

  return <ScreenContainer hasSafeArea={true} scrollable={true} />;
};

export default withTheme(HomeStartedDesignScreen);
