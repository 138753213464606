import * as React from 'react';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import analytics from '@react-native-firebase/analytics';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/DraftbitTheme.js';
import LinkingConfiguration from './LinkingConfiguration.js';

import AboutScreen from './screens/AboutScreen';
import ChallengeDetailScreen from './screens/ChallengeDetailScreen';
import ChallengesListScreen from './screens/ChallengesListScreen';
import FAQScreen from './screens/FAQScreen';
import FeedbackScreen from './screens/FeedbackScreen';
import HelpScreen from './screens/HelpScreen';
import HomeStartedDesignScreen from './screens/HomeStartedDesignScreen';
import LoginScreen from './screens/LoginScreen';
import MemberDashboardScreen from './screens/MemberDashboardScreen';
import SignupScreen from './screens/SignupScreen';
import SurveyEndFeaturedScreen from './screens/SurveyEndFeaturedScreen';
import SurveyEndMovementScreen from './screens/SurveyEndMovementScreen';
import SurveyEndSkipScreen from './screens/SurveyEndSkipScreen';
import SurveyEndThankYouScreen from './screens/SurveyEndThankYouScreen';
import SurveyStartMovementScreen from './screens/SurveyStartMovementScreen';
import TermsScreen from './screens/TermsScreen';
import VideoDetailScreen from './screens/VideoDetailScreen';
import WelcomeScreen from './screens/WelcomeScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function Placeholder() {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#131A2A',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 36,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          fontSize: 24,
          fontWeight: 'bold',
          marginBottom: 12,
          color: '#FFF',
        }}
      >
        Missing Screen
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        This screen is not in a navigator.
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 16,
          color: '#FFF',
          marginBottom: 8,
        }}
      >
        Go to Navigation mode, and click the + (plus) icon in the Navigator tab
        on the left side to add this screen to a Navigator.
      </Text>
      <Text style={{ textAlign: 'center', fontSize: 16, color: '#FFF' }}>
        If the screen is in a Tab Navigator, make sure the screen is assigned to
        a tab in the Config panel on the right.
      </Text>
    </View>
  );
}
function BottomTabNavigator() {
  return (
    <Tab.Navigator
      initialRouteName="LoginScreen"
      tabBarOptions={{
        activeTintColor: theme.colors.custom_rgb16_16_16,
        inactiveTintColor: theme.colors.custom_rgb16_16_16,
        style: {
          backgroundColor: theme.colors.background,
          borderTopColor: 'transparent',
        },
      }}
      backBehavior="history"
    >
      <Tab.Screen
        name="Placeholder"
        component={Placeholder}
        options={{
          mode: 'modal',
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  );
}

function StackNavigator() {
  return (
    <Stack.Navigator
      headerMode="screen"
      initialRouteName="LoginScreen"
      screenOptions={{
        headerTintColor: theme.colors.custom_rgb16_16_16,
      }}
    >
      <Stack.Screen
        name="Placeholder"
        component={Placeholder}
        options={{
          mode: 'modal',
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

export default function RootAppNavigator() {
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  return (
    <NavigationContainer ref={navigationRef} onReady={() => {
      routeNameRef.current = navigationRef.current.getCurrentRoute().name;
    }}
    onStateChange={async () => {
      const previousRouteName = routeNameRef.current;
      const currentRouteName = navigationRef.current.getCurrentRoute().name;

      if (previousRouteName !== currentRouteName) {
        await analytics().logScreenView({
          screen_name: currentRouteName,
          screen_class: currentRouteName,
        });
      }
      routeNameRef.current = currentRouteName;
    }} linking={LinkingConfiguration}>
      <Stack.Navigator headerMode="none" initialRouteName="LoginScreen">
        <Stack.Screen
          name="SurveyEndThankYouScreen"
          component={SurveyEndThankYouScreen}
          options={{
            animationEnabled: true,
            title: 'Survey End Thank You',
          }}
        />
        <Stack.Screen
          name="TermsScreen"
          component={TermsScreen}
          options={{
            animationEnabled: true,
            title: 'Terms',
          }}
        />
        <Stack.Screen
          name="SurveyEndMovementScreen"
          component={SurveyEndMovementScreen}
          options={{
            animationEnabled: true,
            title: 'Survey End - Movement',
          }}
        />
        <Stack.Screen
          name="SurveyEndFeaturedScreen"
          component={SurveyEndFeaturedScreen}
          options={{
            title: 'Survey End - Featured',
          }}
        />
        <Stack.Screen
          name="WelcomeScreen"
          component={WelcomeScreen}
          options={{
            animationEnabled: true,
            title: 'Welcome Screen',
          }}
        />
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={{
            title: 'Login',
          }}
        />
        <Stack.Screen
          name="SurveyStartMovementScreen"
          component={SurveyStartMovementScreen}
          options={{
            title: 'Survey Start - Movement',
          }}
        />
        <Stack.Screen
          name="ChallengeDetailScreen"
          component={ChallengeDetailScreen}
          options={{
            animationEnabled: true,
            title: 'Challenge Detail',
          }}
        />
        <Stack.Screen
          name="ChallengesListScreen"
          component={ChallengesListScreen}
          options={{
            title: 'Challenges List',
          }}
        />
        <Stack.Screen
          name="VideoDetailScreen"
          component={VideoDetailScreen}
          options={{
            animationEnabled: true,
            title: 'Video Detail',
          }}
        />
        <Stack.Screen
          name="SignupScreen"
          component={SignupScreen}
          options={{
            animationEnabled: true,
            title: 'Sign up',
          }}
        />
        <Stack.Screen
          name="SurveyEndSkipScreen"
          component={SurveyEndSkipScreen}
          options={{
            animationEnabled: true,
            title: 'Survey End Skip',
          }}
        />
        <Stack.Screen
          name="AboutScreen"
          component={AboutScreen}
          options={{
            title: 'About',
          }}
        />
        <Stack.Screen
          name="FAQScreen"
          component={FAQScreen}
          options={{
            title: 'FAQ',
          }}
        />
        <Stack.Screen
          name="FeedbackScreen"
          component={FeedbackScreen}
          options={{
            title: 'Feedback',
          }}
        />
        <Stack.Screen
          name="HelpScreen"
          component={HelpScreen}
          options={{
            title: 'Help',
          }}
        />
        <Stack.Screen
          name="MemberDashboardScreen"
          component={MemberDashboardScreen}
          options={{
            title: 'Member Dashboard',
          }}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
        />
        <Stack.Screen name="StackNavigator" component={StackNavigator} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({
    ios: {
      marginRight: 6,
    },
  }),
  headerIconRight: Platform.select({
    ios: {
      marginLeft: 6,
    },
  }),
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({
    ios: {
      marginLeft: 8,
    },
  }),
  headerContainerRight: Platform.select({
    ios: {
      marginRight: 8,
    },
  }),
  headerLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  headerLabel: {
    fontSize: 17,
    letterSpacing: 0.35,
  },
});
