import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Divider,
  Icon,
  IconButton,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const SignupScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    console.log('Screen ON_SCREEN_FOCUS Start');
    let error = null;
    try {
      if (!isFocused) {
        return;
      }
      console.log('Start ON_SCREEN_FOCUS:0 TERMINATION_CHECK');
      if (!Constants['AUTHORIZATION_HEADER']) {
        return;
      }
      console.log('Complete ON_SCREEN_FOCUS:0 TERMINATION_CHECK');
      console.log('Start ON_SCREEN_FOCUS:1 NAVIGATE_NAVIGATOR');
      navigation.navigate('RootNavigator');
      console.log('Complete ON_SCREEN_FOCUS:1 NAVIGATE_NAVIGATOR');
    } catch (err) {
      console.error(err);
      error = err.message ?? err;
    }
    console.log(
      'Screen ON_SCREEN_FOCUS Complete',
      error ? { error } : 'no error'
    );
  }, [isFocused]);

  const [password, setPassword] = React.useState('');
  const [signUpEmailValue, setSignUpEmailValue] = React.useState('');
  const [signup_error, setSignup_error] = React.useState('');
  const [signup_url, setSignup_url] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [username, setUsername] = React.useState('');

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.studilyWhiteBG },
        dimensions.width
      )}
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Header Frame */}
      <View
        style={StyleSheet.applyWidth(
          { flexGrow: 0, flexShrink: 0 },
          dimensions.width
        )}
      >
        {/* Navigation Frame */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', flexGrow: 0, flexShrink: 0 },
            dimensions.width
          )}
        >
          {/* Flex Touchable */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 1, flexShrink: 0 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('LoginScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Left Frame */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', flexGrow: 1, flexShrink: 0 },
                  dimensions.width
                )}
              >
                {/* Icon Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingBottom: 12,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  {/* Back */}
                  <Icon
                    name={'AntDesign/arrowleft'}
                    size={24}
                    color={theme.colors['Custom #101010']}
                  />
                </View>
              </View>
            </Touchable>
          </View>
        </View>
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flex: 1,
            paddingBottom: 36,
            paddingLeft: 36,
            paddingRight: 36,
            paddingTop: 36,
          },
          dimensions.width
        )}
        showsVerticalScrollIndicator={true}
        keyboardShouldPersistTaps={'never'}
      >
        <View>
          {/* Heading */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              style={StyleSheet.applyWidth(
                { height: 58, marginBottom: 20, width: 189 },
                dimensions.width
              )}
              resizeMode={'cover'}
              source={Images.DyAppLoginLogo}
            />
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.strong,
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 16,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'Sign up to your account to continue'}
            </Text>
          </View>
          <Spacer right={8} left={8} bottom={8} top={8} />
          {/* Form */}
          <View>
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.studilyWhite,
                  borderBottomWidth: 1,
                  borderColor: theme.colors.studilyMilkWhite,
                  borderLeftWidth: 1,
                  borderRadius: 12,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  overflow: 'hidden',
                },
                dimensions.width
              )}
            >
              <View>
                {/* Sign up email */}
                <TextInput
                  onChangeText={newSignUpEmailValue => {
                    try {
                      setSignUpEmailValue(newSignUpEmailValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.studilyLightBlue,
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                    },
                    dimensions.width
                  )}
                  value={signUpEmailValue}
                  placeholder={'Enter your company name'}
                  keyboardType={'email-address'}
                  textContentType={'emailAddress'}
                />
              </View>
              <Divider
                style={StyleSheet.applyWidth({ height: 1 }, dimensions.width)}
                color={theme.colors.studilyMilkWhite}
              />
            </View>

            <Spacer top={24} right={8} bottom={24} left={8}>
              {/* Button */}
              <View>
                {/* Touchable Frame */}
                <View
                  style={StyleSheet.applyWidth(
                    { zIndex: 10 },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        console.log('Touchable ON_PRESS Start');
                        let error = null;
                        try {
                          console.log('Start ON_PRESS:0 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'is_refreshing',
                            value: 1,
                          });
                          console.log(
                            'Complete ON_PRESS:0 SET_GLOBAL_VARIABLE'
                          );
                          console.log('Start ON_PRESS:1 FETCH_REQUEST');
                          const signupResponseJson = await DYAPIApi.signUpPOST(
                            Constants,
                            { getlink: signUpEmailValue }
                          );
                          console.log('Complete ON_PRESS:1 FETCH_REQUEST', {
                            signupResponseJson,
                          });
                          console.log('Start ON_PRESS:2 EXTRACT_KEY');
                          const signupUrlResponse =
                            signupResponseJson?.signup_url;
                          console.log('Complete ON_PRESS:2 EXTRACT_KEY', {
                            signupUrlResponse,
                          });
                          console.log(
                            'Start ON_PRESS:3 SET_SCREEN_LOCAL_STATE'
                          );
                          setSignup_url(signupUrlResponse);
                          console.log(
                            'Complete ON_PRESS:3 SET_SCREEN_LOCAL_STATE'
                          );
                          console.log('Start ON_PRESS:4 EXTRACT_KEY');
                          const signup_error = signupResponseJson?.error;
                          console.log('Complete ON_PRESS:4 EXTRACT_KEY', {
                            signup_error,
                          });
                          console.log(
                            'Start ON_PRESS:5 SET_SCREEN_LOCAL_STATE'
                          );
                          setSignup_error(signup_error);
                          console.log(
                            'Complete ON_PRESS:5 SET_SCREEN_LOCAL_STATE'
                          );
                          console.log('Start ON_PRESS:6 SET_GLOBAL_VARIABLE');
                          setGlobalVariableValue({
                            key: 'is_refreshing',
                            value: 0,
                          });
                          console.log(
                            'Complete ON_PRESS:6 SET_GLOBAL_VARIABLE'
                          );
                        } catch (err) {
                          console.error(err);
                          error = err.message ?? err;
                        }
                        console.log(
                          'Touchable ON_PRESS Complete',
                          error ? { error } : 'no error'
                        );
                      };
                      handler();
                    }}
                  >
                    {/* CTA Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors.dyYellow,
                          borderRadius: 12,
                          flexDirection: 'row',
                          justifyContent: 'center',
                          paddingBottom: 15,
                          paddingLeft: 18,
                          paddingRight: 18,
                          paddingTop: 15,
                          zIndex: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: theme.colors['Internal_UI_Dark'],
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 15,
                            lineHeight: 21,
                            textTransform: 'none',
                          },
                          dimensions.width
                        )}
                      >
                        {'Get link'}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              </View>
            </Spacer>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <>
                {!(Constants['is_refreshing'] === 1) ? null : (
                  <ActivityIndicator
                    style={StyleSheet.applyWidth(
                      { borderStyle: 'dotted', height: 36, width: 36 },
                      dimensions.width
                    )}
                    animating={true}
                    hidesWhenStopped={true}
                    size={'large'}
                    color={theme.colors['Custom #ffa500']}
                  />
                )}
              </>
            </View>
          </View>
          {/* Login */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: 8,
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 8,
              },
              dimensions.width
            )}
          >
            <>
              {!signup_error ? null : (
                <Text
                  style={StyleSheet.applyWidth(
                    { color: theme.colors.strong },
                    dimensions.width
                  )}
                >
                  {'No Data Found! Please Contact Administrator'}
                </Text>
              )}
            </>
            <>
              {!signup_error ? null : (
                <Link
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.dYCyan,
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 14,
                      marginTop: 8,
                    },
                    dimensions.width
                  )}
                  selectable={true}
                  accessibilityRole={'link'}
                  accessible={true}
                  title={'support@desk-yogi.com'}
                  dataDetectorType={'email'}
                />
              )}
            </>
            <>
              {!signup_url ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { backgroundColor: '"rgba(0, 0, 0, 0)"' },
                      dimensions.width
                    )}
                  >
                    <>
                      {!Constants['signup_url'] ? null : (
                        <Link
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await WebBrowser.openBrowserAsync(
                                  `${signup_url}`
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              color: 'rgb(95, 95, 95)',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 16,
                              marginTop: 8,
                              textDecorationColor: 'rgb(0, 0, 0)',
                              textDecorationLine: 'underline',
                              textTransform: 'uppercase',
                            },
                            dimensions.width
                          )}
                          dataDetectorType={'link'}
                          selectable={true}
                          accessibilityRole={'link'}
                          accessible={true}
                          title={'Go to registration page '}
                        />
                      )}
                    </>
                  </View>

                  <View>
                    <>
                      {!Constants['signup_url'] ? null : (
                        <IconButton
                          style={StyleSheet.applyWidth(
                            { marginTop: 8 },
                            dimensions.width
                          )}
                          icon={'EvilIcons/external-link'}
                          size={32}
                          color={theme.colors['Custom #101010']}
                        />
                      )}
                    </>
                  </View>
                </View>
              )}
            </>
          </View>

          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.strong,
                paddingBottom: 8,
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 8,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'OR'}
          </Text>

          <Spacer top={24} right={8} bottom={24} left={8}>
            {/* Button */}
            <View>
              {/* Touchable Frame */}
              <View
                style={StyleSheet.applyWidth({ zIndex: 10 }, dimensions.width)}
              >
                <Touchable
                  onPress={() => {
                    console.log('Touchable ON_PRESS Start');
                    let error = null;
                    try {
                      console.log('Start ON_PRESS:0 NAVIGATE_SCREEN');
                      navigation.navigate('LoginScreen');
                      console.log('Complete ON_PRESS:0 NAVIGATE_SCREEN');
                    } catch (err) {
                      console.error(err);
                      error = err.message ?? err;
                    }
                    console.log(
                      'Touchable ON_PRESS Complete',
                      error ? { error } : 'no error'
                    );
                  }}
                >
                  {/* CTA Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.dyYellow,
                        borderRadius: 12,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        paddingBottom: 15,
                        paddingLeft: 18,
                        paddingRight: 18,
                        paddingTop: 15,
                        zIndex: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Label */}
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'center',
                          color: theme.colors['Internal_UI_Dark'],
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 15,
                          lineHeight: 21,
                          textTransform: 'none',
                        },
                        dimensions.width
                      )}
                    >
                      {'Back to Login'}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
            <Spacer right={8} left={8} bottom={8} top={8} />
            {/* Help Link Block */}
            <View
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['Link Block'],
                  { justifyContent: 'center' }
                ),
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { backgroundColor: '"rgba(0, 0, 0, 0)"' },
                  dimensions.width
                )}
              >
                <Link
                  onPress={() => {
                    try {
                      navigation.navigate('HelpScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      color: 'rgb(95, 95, 95)',
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 16,
                      marginTop: 8,
                      textDecorationColor: 'rgb(0, 0, 0)',
                      textDecorationLine: 'underline',
                      textTransform: 'uppercase',
                    },
                    dimensions.width
                  )}
                  dataDetectorType={'link'}
                  selectable={true}
                  accessibilityRole={'link'}
                  accessible={true}
                  title={'Help'}
                />
              </View>
            </View>
          </Spacer>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(SignupScreen);
