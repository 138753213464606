import React from 'react';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useWindowDimensions } from 'react-native';

const SurveyEndFeaturedScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const endSurveyFunction = Variables => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var surveyData = JSON.stringify({
      challenge_id: Variables.CHALLENGE_ID,
      user_id: Variables.USER_ID,
      survey_type: Variables.ending_survey_status,
      email: Variables.EMAIL_ID,
      q1: radioButtonGroupValue,
      q2: radioButtonGroupValue2,
      q3: radioButtonGroupValue3,
      q4: styledTextAreaValue,
    });

    var requestOptions = {
      method: 'post',
      headers: myHeaders,
      redirect: 'follow',
      body: surveyData,
    };

    fetch(
      'https://members.desk-yogi.com/wp-json/wp/v2/addSurveyData/',
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'SHOT',
          value: true,
        });
        const surveyStatusResponse = await DYAPIApi.surveyStatusPOST(
          Constants,
          {
            challenge_id: Constants['CHALLENGE_ID'],
            survey_status: Constants['ending_survey_status'],
            user_id: Constants['USER_ID'],
          }
        );
        const surveyStatusCheck = surveyStatusResponse?.status;
        setGlobalVariableValue({
          key: 'survey_status_check',
          value: surveyStatusCheck,
        });
        const surveryCheckEndUrl = surveyStatusResponse?.survery_url;
        setGlobalVariableValue({
          key: 'survery_end_url',
          value: surveryCheckEndUrl,
        });
        if (surveyStatusCheck === 0) {
          return;
        }
        navigation.navigate('RootNavigator');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [menuTab1, setMenuTab1] = React.useState(true);
  const [menuTab2, setMenuTab2] = React.useState(false);
  const [menuTab3, setMenuTab3] = React.useState(false);
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [radioButtonGroupValue2, setRadioButtonGroupValue2] =
    React.useState('');
  const [radioButtonGroupValue3, setRadioButtonGroupValue3] =
    React.useState('');
  const [showVideo, setShowVideo] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [starRatingValue, setStarRatingValue] = React.useState(0);
  const [styledTextAreaValue, setStyledTextAreaValue] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      scrollable={false}
      hasTopSafeArea={true}
      hasBottomSafeArea={false}
      hasSafeArea={false}
    />
  );
};

export default withTheme(SurveyEndFeaturedScreen);
