import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Link,
  RadioButton,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  Surface,
  TextField,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const SurveyStartMovementScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const startSurveyFunction = Variables => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var surveyData = JSON.stringify({
      challenge_id: Variables.CHALLENGETYPE_ID,
      user_id: Variables.USER_ID,
      survey_type: Variables.starting_survey_status,
      email: Variables.EMAIL_ID,
      q1: radioButtonGroupValue,
      q2: radioButtonGroupValue2,
      // q3: radioButtonGroupValue3,
      // q4: styledTextAreaValue
    });

    console.log(surveyData);

    var requestOptions = {
      method: 'post',
      headers: myHeaders,
      redirect: 'follow',
      body: surveyData,
    };

    fetch(
      'https://members.desk-yogi.com/wp-json/wp/v2/addSurveyData/',
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setShowVideo(0);
        setGlobalVariableValue({
          key: 'SHOT',
          value: true,
        });
        setGlobalVariableValue({
          key: 'CHALLENGETYPE_ID',
          value: props.route?.params?.challenge_id ?? '',
        });
        setGlobalVariableValue({
          key: 'starting_survey_status',
          value: 'start',
        });
        const surveyStatusResponse = await DYAPIApi.newHomePageGET(Constants, {
          user_id: Constants['USER_ID'],
        });
        const surveyStatusCheck = surveyStatusResponse?.videos.btnstatus;
        setSurvey_status_check(surveyStatusCheck);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [menuTab1, setMenuTab1] = React.useState(true);
  const [menuTab2, setMenuTab2] = React.useState(false);
  const [menuTab3, setMenuTab3] = React.useState(false);
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [radioButtonGroupValue2, setRadioButtonGroupValue2] =
    React.useState('');
  const [radioButtonGroupValue3, setRadioButtonGroupValue3] =
    React.useState('');
  const [showVideo, setShowVideo] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [starRatingValue, setStarRatingValue] = React.useState(0);
  const [styledTextAreaValue, setStyledTextAreaValue] = React.useState('');
  const [survey_status_check, setSurvey_status_check] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      scrollable={false}
      hasTopSafeArea={true}
      hasBottomSafeArea={false}
      hasSafeArea={false}
    >
      {/* Menu */}
      <>
        {!showVideo ? null : (
          <Surface
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Drawer'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  top: '10%',
                },
                dimensions.width
              )}
            >
              {/* Challenges */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ChallengesListScreen', {
                          id: props.route?.params?.user_id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Ionicons/trophy-outline'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Challenges'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* About */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('AboutScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/infocirlceo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'About'}
                  </Text>
                </View>
              </Touchable>
              {/* Help */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/questioncircleo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Help'}
                  </Text>
                </View>
              </Touchable>
              {/* FAQ */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FAQScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Octicons/tasklist'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'FAQ'}
                  </Text>
                </View>
              </Touchable>
              {/* Feedback */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FeedbackScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Feather/message-square'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Feedback'}
                  </Text>
                </View>
              </Touchable>
              {/* Member Dashboard */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('MemberDashboardScreen', {
                          user_id: props.route?.params?.user_id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Feather/settings'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Member Dashboard'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Terms */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('TermsScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Ionicons/ios-document-text-outline'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Terms'}
                  </Text>
                </View>
              </Touchable>
              {/* Login */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] === '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Login'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Logout */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Logout'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
            </View>
          </Surface>
        )}
      </>
      <>
        {/* Head Wrap */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors.divider,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
              paddingBottom: 15,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Header Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 0, flexShrink: 0 },
              dimensions.width
            )}
          >
            {/* Navigation Frame */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', flexGrow: 0, flexShrink: 0 },
                dimensions.width
              )}
            >
              {/* Flex Touchable */}
              <View
                style={StyleSheet.applyWidth(
                  { flexGrow: 1, flexShrink: 0 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'SHOT',
                        value: false,
                      });
                      navigation.navigate('ChallengesListScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Left Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', flexGrow: 1, flexShrink: 0 },
                      dimensions.width
                    )}
                  >
                    {/* Icon Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 12,
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Back */}
                      <Icon
                        name={'AntDesign/arrowleft'}
                        size={24}
                        color={theme.colors['Custom #101010']}
                      />
                    </View>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
          {/* Logo */}
          <View>
            <Touchable>
              <Image
                style={StyleSheet.applyWidth(
                  { height: 42, width: 66 },
                  dimensions.width
                )}
                resizeMode={'cover'}
                source={Images.DyAppLogo}
              />
            </Touchable>
          </View>
          {/* Main Menu */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('HelpScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginRight: 15 },
                dimensions.width
              )}
              color={theme.colors['Custom #101010']}
              icon={'AntDesign/questioncircleo'}
              size={28}
            />
            <Checkbox
              onPress={newCheckboxValue => {
                try {
                  setShowVideo(newCheckboxValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              status={showVideo}
              uncheckedIcon={'Entypo/menu'}
              color={theme.colors['Custom #101010']}
              uncheckedColor={theme.colors['Custom #101010']}
              checkedIcon={'Feather/x'}
              size={32}
            />
          </View>
        </View>
      </>
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={true}
        keyboardShouldPersistTaps={'always'}
        enableOnAndroid={true}
        enableAutomaticScroll={true}
        enableResetScrollToCoords={true}
      >
        <DYAPIApi.FetchStartSurveyPOST>
          {({ loading, error, data, refetchStartSurvey }) => {
            const fetchData = data;
            if (!fetchData || loading) {
              return <ActivityIndicator />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center' }}>
                  There was a problem fetching this data
                </Text>
              );
            }

            return (
              <>
                {/* Challenge 1 Starting Form */}
                <>
                  {!((props.route?.params?.category_id ?? 1) === 1) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingLeft: 16, paddingRight: 16 },
                          dimensions.width
                        )}
                      >
                        <>
                          {!fetchData?.heading ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_700Bold',
                                  fontSize: 28,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.heading}
                            </Text>
                          )}
                        </>
                        <>
                          {!fetchData?.content ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.content}
                            </Text>
                          )}
                        </>
                      </View>

                      <Surface
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 8,
                            marginTop: 16,
                            minHeight: 40,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                        elevation={1}
                      >
                        {/* Question 1 */}
                        <View>
                          <View>
                            <>
                              {!fetchData?.que1 ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.que1}
                                </Text>
                              )}
                            </>
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <RadioButtonGroup
                                onValueChange={newRadioButtonGroupValue => {
                                  try {
                                    setRadioButtonGroupValue(
                                      newRadioButtonGroupValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                value={radioButtonGroupValue}
                                direction={'horizontal'}
                              >
                                <RadioButton
                                  value={1}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-frown-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-frown'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_3']
                                  }
                                  size={65}
                                  color={theme.colors['Custom Color_3']}
                                />
                                <RadioButton
                                  value={2}
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-sad'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_4']
                                  }
                                  size={65}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-sad-outline'
                                  }
                                  color={theme.colors['Custom Color_4']}
                                />
                                <RadioButton
                                  value={3}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-neutral-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-neutral'
                                  }
                                  color={theme.colors['Custom Color_5']}
                                  unselectedColor={
                                    theme.colors['Custom Color_5']
                                  }
                                  size={65}
                                />
                                <RadioButton
                                  value={4}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-happy-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-happy'
                                  }
                                  color={theme.colors['Custom Color_6']}
                                  unselectedColor={
                                    theme.colors['Custom Color_6']
                                  }
                                  size={65}
                                />
                                <RadioButton
                                  value={5}
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon'
                                  }
                                  color={theme.colors['Custom Color_7']}
                                  size={65}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-outline'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_7']
                                  }
                                />
                              </RadioButtonGroup>
                            </View>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'not good'}
                              </Text>
                            </View>

                            <View>
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'great'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </Surface>

                      <Surface
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 8,
                            marginTop: 8,
                            minHeight: 40,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                        elevation={1}
                      >
                        {/* Question 2 */}
                        <View>
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 16 },
                              dimensions.width
                            )}
                          >
                            <>
                              {!fetchData?.que2 ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.que2}
                                </Text>
                              )}
                            </>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'flex-start',
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <RadioButtonGroup
                              onValueChange={newRadioButtonGroupValue => {
                                try {
                                  setRadioButtonGroupValue2(
                                    newRadioButtonGroupValue
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              value={radioButtonGroupValue2}
                              direction={'vertical'}
                            >
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Strongly Agree'}
                                label={'Strongly Agree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Agree'}
                                label={'Agree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Neutral'}
                                label={'Neutral'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Disagree'}
                                label={'Disagree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Strongly Disagree'}
                                label={'Strongly Disagree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                            </RadioButtonGroup>
                          </View>
                        </View>
                      </Surface>
                      {/* Activity Indicator */}
                      <>
                        {!(Constants['is_refreshing'] === 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'Activity Indicator 2'
                                ],
                                { alignContent: 'center' }
                              ),
                              dimensions.width
                            )}
                          >
                            <>
                              {!(Constants['is_refreshing'] === 1) ? null : (
                                <ActivityIndicator
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderStyle: 'dotted',
                                      height: 36,
                                      width: 36,
                                    },
                                    dimensions.width
                                  )}
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'large'}
                                  color={theme.colors['Custom #ffa500']}
                                />
                              )}
                            </>
                          </View>
                        )}
                      </>
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_refreshing',
                                value: 1,
                              });
                              startSurveyFunction(Variables);
                              const startSurveyStatusCheck =
                                await DYAPIApi.surveyStatusPOST(Constants, {
                                  challenge_id: Constants['CHALLENGE_ID'],
                                  survey_status:
                                    Constants['starting_survey_status'],
                                  user_id: Constants['USER_ID'],
                                });
                              const surveyEndStatus =
                                startSurveyStatusCheck?.status;
                              setGlobalVariableValue({
                                key: 'survey_end_status',
                                value: surveyEndStatus,
                              });
                              navigation.navigate('ChallengeDetailScreen', {
                                user_id: Constants['USER_ID'],
                                survey_status:
                                  props.route?.params?.survey_status ?? '',
                                category_id:
                                  props.route?.params?.category_id ?? 1,
                                challenge_id:
                                  props.route?.params?.challenge_id ?? '',
                              });
                              setGlobalVariableValue({
                                key: 'is_refreshing',
                                value: 0,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['DY - Cyan'],
                            borderRadius: 8,
                            color: 'rgb(0,0,0)',
                            fontFamily: 'Poppins_700Bold',
                            marginBottom: 8,
                            marginTop: 8,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                        title={'Submit'}
                      />
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ChallengeDetailScreen', {
                              challenge_id:
                                props.route?.params?.challenge_id ?? '',
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Link
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'SHOT',
                                value: false,
                              });
                              startSurveyFunction(Variables);
                              navigation.navigate('ChallengeDetailScreen', {
                                user_id: Constants['USER_ID'],
                                survey_status:
                                  props.route?.params?.survey_status ?? '',
                                category_id:
                                  props.route?.params?.category_id ?? 1,
                                challenge_id:
                                  props.route?.params?.challenge_id ?? '',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Dy - Yellow'],
                              fontFamily: 'Poppins_400Regular',
                              marginBottom: 8,
                              marginTop: 8,
                              textAlign: 'center',
                              textDecorationLine: 'underline',
                            },
                            dimensions.width
                          )}
                          title={'Skip'}
                          dataDetectorType={'link'}
                          accessibilityRole={'button'}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
                {/* Challenge 2 Starting Form */}
                <>
                  {!((props.route?.params?.category_id ?? 1) === 2) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingLeft: 16, paddingRight: 16 },
                          dimensions.width
                        )}
                      >
                        <>
                          {!fetchData?.heading ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_700Bold',
                                  fontSize: 28,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.heading}
                            </Text>
                          )}
                        </>
                        <>
                          {!fetchData?.content ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.content}
                            </Text>
                          )}
                        </>
                      </View>

                      <Surface
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 8,
                            marginTop: 16,
                            minHeight: 40,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                        elevation={1}
                      >
                        {/* Question 1 */}
                        <View>
                          <View>
                            <>
                              {!fetchData?.que1 ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.que1}
                                </Text>
                              )}
                            </>
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <RadioButtonGroup
                                onValueChange={newRadioButtonGroupValue => {
                                  try {
                                    setRadioButtonGroupValue(
                                      newRadioButtonGroupValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                direction={'horizontal'}
                              >
                                <RadioButton
                                  value={1}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-frown-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-frown'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_3']
                                  }
                                  size={65}
                                  color={theme.colors['Custom Color_3']}
                                />
                                <RadioButton
                                  value={2}
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-sad'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_4']
                                  }
                                  size={65}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-sad-outline'
                                  }
                                  color={theme.colors['Custom Color_4']}
                                />
                                <RadioButton
                                  value={3}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-neutral-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-neutral'
                                  }
                                  color={theme.colors['Custom Color_5']}
                                  unselectedColor={
                                    theme.colors['Custom Color_5']
                                  }
                                  size={65}
                                />
                                <RadioButton
                                  value={4}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-happy-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-happy'
                                  }
                                  color={theme.colors['Custom Color_6']}
                                  unselectedColor={
                                    theme.colors['Custom Color_6']
                                  }
                                  size={65}
                                />
                                <RadioButton
                                  value={5}
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon'
                                  }
                                  color={theme.colors['Custom Color_7']}
                                  size={65}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-outline'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_7']
                                  }
                                />
                              </RadioButtonGroup>
                            </View>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'not good'}
                              </Text>
                            </View>

                            <View>
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'great'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </Surface>

                      <Surface
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 8,
                            marginTop: 8,
                            minHeight: 40,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                        elevation={1}
                      >
                        {/* Question 2 */}
                        <View>
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 16 },
                              dimensions.width
                            )}
                          >
                            <>
                              {!fetchData?.que2 ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.que2}
                                </Text>
                              )}
                            </>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'flex-start',
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <RadioButtonGroup
                              onValueChange={newRadioButtonGroupValue => {
                                try {
                                  setRadioButtonGroupValue2(
                                    newRadioButtonGroupValue
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              direction={'vertical'}
                            >
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Strongly Agree'}
                                label={'Strongly Agree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Agree'}
                                label={'Agree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Neutral'}
                                label={'Neutral'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Disagree'}
                                label={'Disagree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Strongly Disagree'}
                                label={'Strongly Disagree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                            </RadioButtonGroup>
                          </View>
                        </View>
                      </Surface>
                      {/* Activity Indicator */}
                      <>
                        {!(Constants['is_refreshing'] === 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'Activity Indicator 2'
                                ],
                                { alignContent: 'center' }
                              ),
                              dimensions.width
                            )}
                          >
                            <>
                              {!(Constants['is_refreshing'] === 1) ? null : (
                                <ActivityIndicator
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderStyle: 'dotted',
                                      height: 36,
                                      width: 36,
                                    },
                                    dimensions.width
                                  )}
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'large'}
                                  color={theme.colors['Custom #ffa500']}
                                />
                              )}
                            </>
                          </View>
                        )}
                      </>
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_refreshing',
                                value: 1,
                              });
                              startSurveyFunction(Variables);
                              const startSurveyStatusCheck =
                                await DYAPIApi.surveyStatusPOST(Constants, {
                                  challenge_id: Constants['CHALLENGE_ID'],
                                  survey_status:
                                    Constants['starting_survey_status'],
                                  user_id: Constants['USER_ID'],
                                });
                              const surveyEndStatus =
                                startSurveyStatusCheck?.status;
                              setGlobalVariableValue({
                                key: 'survey_end_status',
                                value: surveyEndStatus,
                              });
                              navigation.navigate('ChallengeDetailScreen', {
                                user_id: Constants['USER_ID'],
                                survey_status:
                                  props.route?.params?.survey_status ?? '',
                                category_id:
                                  props.route?.params?.category_id ?? 1,
                                challenge_id:
                                  props.route?.params?.challenge_id ?? '',
                              });
                              setGlobalVariableValue({
                                key: 'is_refreshing',
                                value: 0,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['DY - Cyan'],
                            borderRadius: 8,
                            fontFamily: 'Poppins_700Bold',
                            marginBottom: 8,
                            marginTop: 8,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                        title={'Submit'}
                      />
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ChallengeDetailScreen', {
                              challenge_id:
                                props.route?.params?.challenge_id ?? '',
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Link
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'SHOT',
                                value: false,
                              });
                              startSurveyFunction(Variables);
                              navigation.navigate('ChallengeDetailScreen', {
                                user_id: Constants['USER_ID'],
                                survey_status:
                                  props.route?.params?.survey_status ?? '',
                                category_id:
                                  props.route?.params?.category_id ?? 1,
                                challenge_id:
                                  props.route?.params?.challenge_id ?? '',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Dy - Yellow'],
                              fontFamily: 'Poppins_400Regular',
                              marginBottom: 8,
                              marginTop: 8,
                              textAlign: 'center',
                              textDecorationLine: 'underline',
                            },
                            dimensions.width
                          )}
                          dataDetectorType={'link'}
                          title={'Skip'}
                          accessibilityRole={'button'}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
                {/* Challenge 3 Starting Form */}
                <>
                  {!((props.route?.params?.category_id ?? 1) === 3) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingLeft: 16, paddingRight: 16 },
                          dimensions.width
                        )}
                      >
                        <>
                          {!fetchData?.heading ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_700Bold',
                                  fontSize: 28,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.heading}
                            </Text>
                          )}
                        </>
                        <>
                          {!fetchData?.content ? null : (
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.content}
                            </Text>
                          )}
                        </>
                      </View>

                      <Surface
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 8,
                            marginTop: 16,
                            minHeight: 40,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                        elevation={1}
                      >
                        {/* Question 1 */}
                        <View>
                          <View>
                            <>
                              {!fetchData?.que1 ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.que1}
                                </Text>
                              )}
                            </>
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              <RadioButtonGroup
                                onValueChange={newRadioButtonGroupValue => {
                                  try {
                                    setRadioButtonGroupValue(
                                      newRadioButtonGroupValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                direction={'horizontal'}
                              >
                                <RadioButton
                                  value={1}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-frown-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-frown'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_3']
                                  }
                                  size={65}
                                  color={theme.colors['Custom Color_3']}
                                />
                                <RadioButton
                                  value={2}
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-sad'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_4']
                                  }
                                  size={65}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-sad-outline'
                                  }
                                  color={theme.colors['Custom Color_4']}
                                />
                                <RadioButton
                                  value={3}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-neutral-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-neutral'
                                  }
                                  color={theme.colors['Custom Color_5']}
                                  unselectedColor={
                                    theme.colors['Custom Color_5']
                                  }
                                  size={65}
                                />
                                <RadioButton
                                  value={4}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-happy-outline'
                                  }
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon-happy'
                                  }
                                  color={theme.colors['Custom Color_6']}
                                  unselectedColor={
                                    theme.colors['Custom Color_6']
                                  }
                                  size={65}
                                />
                                <RadioButton
                                  value={5}
                                  selectedIcon={
                                    'MaterialCommunityIcons/emoticon'
                                  }
                                  color={theme.colors['Custom Color_7']}
                                  size={65}
                                  unselectedIcon={
                                    'MaterialCommunityIcons/emoticon-outline'
                                  }
                                  unselectedColor={
                                    theme.colors['Custom Color_7']
                                  }
                                />
                              </RadioButtonGroup>
                            </View>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'not good'}
                              </Text>
                            </View>

                            <View>
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'great'}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </Surface>

                      <Surface
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 8,
                            marginTop: 8,
                            minHeight: 40,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                        elevation={1}
                      >
                        {/* Question 2 */}
                        <View>
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 16 },
                              dimensions.width
                            )}
                          >
                            <>
                              {!fetchData?.que2 ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: 'Poppins_600SemiBold',
                                      fontSize: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {fetchData?.que2}
                                </Text>
                              )}
                            </>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'flex-start',
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                marginTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <RadioButtonGroup
                              onValueChange={newRadioButtonGroupValue => {
                                try {
                                  setRadioButtonGroupValue2(
                                    newRadioButtonGroupValue
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              direction={'vertical'}
                            >
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Strongly Agree'}
                                label={'Strongly Agree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Agree'}
                                label={'Agree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Neutral'}
                                label={'Neutral'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Disagree'}
                                label={'Disagree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                              <RadioButtonRow
                                style={StyleSheet.applyWidth(
                                  {
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                                value={'Strongly Disagree'}
                                label={'Strongly Disagree'}
                                color={theme.colors['Dy - Yellow']}
                                unselectedColor={theme.colors['Dy - Yellow']}
                                direction={'row'}
                              />
                            </RadioButtonGroup>
                          </View>
                        </View>
                      </Surface>
                      {/* Activity Indicator */}
                      <>
                        {!(Constants['is_refreshing'] === 1) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'Activity Indicator 2'
                                ],
                                { alignContent: 'center' }
                              ),
                              dimensions.width
                            )}
                          >
                            <>
                              {!(Constants['is_refreshing'] === 1) ? null : (
                                <ActivityIndicator
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderStyle: 'dotted',
                                      height: 36,
                                      width: 36,
                                    },
                                    dimensions.width
                                  )}
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'large'}
                                  color={theme.colors['Custom #ffa500']}
                                />
                              )}
                            </>
                          </View>
                        )}
                      </>
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_refreshing',
                                value: 1,
                              });
                              startSurveyFunction(Variables);
                              const startSurveyStatusCheck =
                                await DYAPIApi.surveyStatusPOST(Constants, {
                                  challenge_id: Constants['CHALLENGE_ID'],
                                  survey_status:
                                    Constants['starting_survey_status'],
                                  user_id: Constants['USER_ID'],
                                });
                              const surveyEndStatus =
                                startSurveyStatusCheck?.status;
                              setGlobalVariableValue({
                                key: 'survey_end_status',
                                value: surveyEndStatus,
                              });
                              navigation.navigate('ChallengeDetailScreen', {
                                user_id: Constants['USER_ID'],
                                survey_status:
                                  props.route?.params?.survey_status ?? '',
                                category_id:
                                  props.route?.params?.category_id ?? 1,
                                challenge_id:
                                  props.route?.params?.challenge_id ?? '',
                              });
                              setGlobalVariableValue({
                                key: 'is_refreshing',
                                value: 0,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['DY - Cyan'],
                            borderRadius: 8,
                            fontFamily: 'Poppins_700Bold',
                            marginBottom: 8,
                            marginTop: 8,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                        title={'Submit'}
                      />
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('ChallengeDetailScreen', {
                              challenge_id:
                                props.route?.params?.challenge_id ?? '',
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Link
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'SHOT',
                                value: false,
                              });
                              navigation.navigate('ChallengeDetailScreen', {
                                user_id: Constants['USER_ID'],
                                survey_status:
                                  props.route?.params?.survey_status ?? '',
                                category_id:
                                  props.route?.params?.category_id ?? 1,
                                challenge_id:
                                  props.route?.params?.challenge_id ?? '',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Dy - Yellow'],
                              fontFamily: 'Poppins_400Regular',
                              marginBottom: 8,
                              marginTop: 8,
                              textAlign: 'center',
                              textDecorationLine: 'underline',
                            },
                            dimensions.width
                          )}
                          title={'Skip'}
                          dataDetectorType={'link'}
                          accessibilityRole={'button'}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
              </>
            );
          }}
        </DYAPIApi.FetchStartSurveyPOST>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(SurveyStartMovementScreen);
