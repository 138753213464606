import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  Circle,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const VideoDetailScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShowVideo(false);
      if (Constants['AUTHORIZATION_HEADER']) {
        return;
      }
      navigation.navigate('LoginScreen');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [showVideo, setShowVideo] = React.useState(false);
  const [showVideoDetail, setShowVideoDetail] = React.useState(false);

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { borderTopLeftRadius: 32 },
        dimensions.width
      )}
      hasSafeArea={true}
      scrollable={true}
    >
      {/* Menu */}
      <>
        {!showVideo ? null : (
          <Surface
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Drawer'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  top: '10%',
                },
                dimensions.width
              )}
            >
              {/* Challenges */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ChallengesListScreen', {
                          id: props.route?.params?.id ?? 27905,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Ionicons/trophy-outline'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Challenges'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* About */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('AboutScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/infocirlceo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'About'}
                  </Text>
                </View>
              </Touchable>
              {/* Help */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/questioncircleo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Help'}
                  </Text>
                </View>
              </Touchable>
              {/* FAQ */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FAQScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Octicons/tasklist'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'FAQ'}
                  </Text>
                </View>
              </Touchable>
              {/* Feedback */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FeedbackScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Feather/message-square'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Feedback'}
                  </Text>
                </View>
              </Touchable>
              {/* Member Dashboard */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('MemberDashboardScreen', {
                          user_id: props.route?.params?.id ?? 27905,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Feather/settings'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Member Dashboard'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Terms */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('TermsScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Ionicons/ios-document-text-outline'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Terms'}
                  </Text>
                </View>
              </Touchable>
              {/* Login */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] === '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Login'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Logout */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Logout'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
            </View>
          </Surface>
        )}
      </>
      <>
        {/* Head Wrap */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors.divider,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
              paddingBottom: 15,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Header Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 0, flexShrink: 0 },
              dimensions.width
            )}
          >
            {/* Navigation Frame */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', flexGrow: 0, flexShrink: 0 },
                dimensions.width
              )}
            >
              {/* Flex Touchable */}
              <View
                style={StyleSheet.applyWidth(
                  { flexGrow: 1, flexShrink: 0 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setShowVideoDetail(false);
                      navigation.navigate('ChallengeDetailScreen', {
                        user_id: Constants['USER_ID'],
                        challenge_id: props.route?.params?.challenge_id ?? '',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Left Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', flexGrow: 1, flexShrink: 0 },
                      dimensions.width
                    )}
                  >
                    {/* Icon Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 12,
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Back */}
                      <Icon
                        name={'AntDesign/arrowleft'}
                        size={24}
                        color={theme.colors['Custom #101010']}
                      />
                    </View>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
          {/* Logo */}
          <View>
            <Touchable>
              <Image
                style={StyleSheet.applyWidth(
                  { height: 42, width: 66 },
                  dimensions.width
                )}
                resizeMode={'cover'}
                source={Images.DyAppLogo}
              />
            </Touchable>
          </View>
          {/* Main Menu */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('HelpScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginRight: 15 },
                dimensions.width
              )}
              icon={'AntDesign/questioncircleo'}
              size={28}
              color={theme.colors['Custom #101010']}
            />
            <Checkbox
              onPress={newCheckboxValue => {
                try {
                  setShowVideo(newCheckboxValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              status={showVideo}
              uncheckedIcon={'Entypo/menu'}
              color={theme.colors['Custom #101010']}
              uncheckedColor={theme.colors['Custom #101010']}
              checkedIcon={'Feather/x'}
              size={32}
            />
          </View>
        </View>
      </>
      <DYAPIApi.FetchVideoDetailPOST
        challenge_id={props.route?.params?.challenge_id ?? ''}
        video_id={props.route?.params?.id ?? 27905}
      >
        {({ loading, error, data, refetchVideoDetail }) => {
          const fetchData = data;
          if (!fetchData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <FlatList
              renderItem={({ item }) => {
                const listData = item;
                return (
                  <>
                    {/* Video Wrap */}
                    <>
                      {!listData ? null : (
                        <View>
                          {/* View Frame */}
                          <View>
                            <>
                              {showVideoDetail ? null : (
                                <ImageBackground
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageBackgroundStyles(theme)[
                                        'Image Background'
                                      ],
                                      {
                                        alignItems: 'center',
                                        height: 200,
                                        justifyContent: 'center',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  resizeMode={'cover'}
                                  source={{ uri: `${listData?.image}` }}
                                >
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setShowVideoDetail(true);
                                          await DYAPIApi.videoTrackingPOST(
                                            Constants,
                                            {
                                              challenge_id:
                                                props.route?.params
                                                  ?.challenge_id ?? '',
                                              user_id: Constants['USER_ID'],
                                              video_id:
                                                props.route?.params?.id ??
                                                27905,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    disabled={showVideoDetail}
                                  >
                                    <Circle size={96}>
                                      <Icon
                                        size={64}
                                        name={'Feather/play-circle'}
                                        color={theme.colors['Surface']}
                                      />
                                    </Circle>
                                  </Touchable>
                                </ImageBackground>
                              )}
                            </>
                            <>
                              {!showVideoDetail ? null : (
                                <VideoPlayer
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.VideoPlayerStyles(theme)[
                                        'Video'
                                      ],
                                      { height: 200, width: '100%' }
                                    ),
                                    dimensions.width
                                  )}
                                  resizeMode={'cover'}
                                  useNativeControls={true}
                                  usePoster={true}
                                  shouldPlay={true}
                                  source={{
                                    uri: `${listData?.wistiamediaidlink}`,
                                  }}
                                />
                              )}
                            </>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Title */}
                    <>
                      {!listData ? null : (
                        <View>
                          <>
                            {!listData?.title ? null : (
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.strong,
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {listData?.title}
                              </Text>
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* Meta Wrap */}
                    <>
                      {!listData ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 5,
                            },
                            dimensions.width
                          )}
                        >
                          <View>
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {'with '}
                              {listData?.author}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Icon
                              style={StyleSheet.applyWidth(
                                { marginTop: -3 },
                                dimensions.width
                              )}
                              size={20}
                              name={'EvilIcons/clock'}
                            />
                            <>
                              {!listData?.duration ? null : (
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.strong,
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.duration}
                                </Text>
                              )}
                            </>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Description */}
                    <>
                      {!listData ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingLeft: 16, paddingRight: 16 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'Poppins_400Regular',
                              },
                              dimensions.width
                            )}
                          >
                            {listData?.content}
                          </Text>
                        </View>
                      )}
                    </>
                  </>
                );
              }}
              data={fetchData?.content}
              listKey={'1mByXwMy'}
              keyExtractor={listData =>
                listData?.id || listData?.uuid || JSON.stringify(listData)
              }
              contentContainerStyle={StyleSheet.applyWidth(
                { flex: 1 },
                dimensions.width
              )}
              numColumns={1}
            />
          );
        }}
      </DYAPIApi.FetchVideoDetailPOST>
      <Button
        onPress={() => {
          try {
            setShowVideoDetail(false);
            navigation.navigate('ChallengeDetailScreen', {
              user_id: Constants['USER_ID'],
              challenge_id: props.route?.params?.challenge_id ?? '',
            });
          } catch (err) {
            console.error(err);
          }
        }}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['DY - Cyan'],
            borderRadius: 8,
            color: 'rgb(0,0,0)',
            fontFamily: 'Poppins_700Bold',
            marginBottom: 16,
            marginLeft: 16,
            marginRight: 16,
            textAlign: 'center',
          },
          dimensions.width
        )}
        title={'Back to the challenge'}
      />
    </ScreenContainer>
  );
};

export default withTheme(VideoDetailScreen);
