import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Checkbox,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const ChallengeDetailScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const surveyProgressCheck = await DYAPIApi.getChallengeDataPOST(
          Constants,
          {
            challenge_id: props.route?.params?.challenge_id ?? 1,
            user_id: Constants['USER_ID'],
          }
        );
        const surveyProgressResponse = surveyProgressCheck?.content.progress;
        const challenge1DataProgressCaption =
          surveyProgressCheck?.content.progressCaption;
        setGlobalVariableValue({
          key: 'challengeDetailDataProgressCaption1',
          value: challenge1DataProgressCaption,
        });
        if (surveyProgressResponse !== '100%') {
          return;
        }
        navigation.navigate('SurveyEndMovementScreen', {
          user_id: Constants['USER_ID'],
          survey_status: 'end',
          challenge_id: props.route?.params?.challenge_id ?? 1,
          category_id: parseInt(props.route?.params?.category_id ?? 1, 10),
        });
        const surveyStatusResponse = await DYAPIApi.surveyStatusPOST(
          Constants,
          {
            challenge_id: Constants['CHALLENGE_ID'],
            survey_status: Constants['ending_survey_status'],
            user_id: Constants['USER_ID'],
          }
        );
        const surveyStatusCheck = surveyStatusResponse?.status;
        if (surveyStatusCheck === 0) {
          return;
        }
        if (!surveyStatusCheck) {
          return;
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <ScreenContainer
      style={StyleSheet.applyWidth(
        { borderTopLeftRadius: 32 },
        dimensions.width
      )}
      hasSafeArea={true}
      scrollable={true}
    >
      {/* Menu */}
      <>
        {!showVideo ? null : (
          <Surface
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Drawer'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  top: '10%',
                },
                dimensions.width
              )}
            >
              {/* Challenges */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ChallengesListScreen', {
                          id: props.route?.params?.user_id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Ionicons/trophy-outline'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Challenges'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* About */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('AboutScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/infocirlceo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'About'}
                  </Text>
                </View>
              </Touchable>
              {/* Help */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/questioncircleo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Help'}
                  </Text>
                </View>
              </Touchable>
              {/* FAQ */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FAQScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Octicons/tasklist'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'FAQ'}
                  </Text>
                </View>
              </Touchable>
              {/* Feedback */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FeedbackScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Feather/message-square'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Feedback'}
                  </Text>
                </View>
              </Touchable>
              {/* Member Dashboard */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('MemberDashboardScreen', {
                          user_id: props.route?.params?.user_id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Feather/settings'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Member Dashboard'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Terms */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('TermsScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Ionicons/ios-document-text-outline'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Terms'}
                  </Text>
                </View>
              </Touchable>
              {/* Login */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] === '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Login'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Logout */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Logout'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
            </View>
          </Surface>
        )}
      </>
      <>
        {/* Head Wrap */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors.divider,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
              paddingBottom: 15,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Header Frame */}
          <View
            style={StyleSheet.applyWidth(
              { flexGrow: 0, flexShrink: 0 },
              dimensions.width
            )}
          >
            {/* Navigation Frame */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', flexGrow: 0, flexShrink: 0 },
                dimensions.width
              )}
            >
              {/* Flex Touchable */}
              <View
                style={StyleSheet.applyWidth(
                  { flexGrow: 1, flexShrink: 0 },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('ChallengesListScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Left Frame */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', flexGrow: 1, flexShrink: 0 },
                      dimensions.width
                    )}
                  >
                    {/* Icon Frame */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 12,
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Back */}
                      <Icon
                        name={'AntDesign/arrowleft'}
                        size={24}
                        color={theme.colors['Custom #101010']}
                      />
                    </View>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
          {/* Logo */}
          <View>
            <Touchable>
              <Image
                style={StyleSheet.applyWidth(
                  { height: 42, width: 66 },
                  dimensions.width
                )}
                resizeMode={'cover'}
                source={Images.DyAppLogo}
              />
            </Touchable>
          </View>
          {/* Main Menu */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('HelpScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginRight: 15 },
                dimensions.width
              )}
              color={theme.colors['Custom #101010']}
              size={28}
              icon={'AntDesign/questioncircleo'}
            />
            <Checkbox
              onPress={newCheckboxValue => {
                try {
                  setShowVideo(newCheckboxValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              status={showVideo}
              uncheckedIcon={'Entypo/menu'}
              color={theme.colors['Custom #101010']}
              uncheckedColor={theme.colors['Custom #101010']}
              checkedIcon={'Feather/x'}
              size={32}
            />
          </View>
        </View>
      </>
      {/* Daily Practice Section */}
      <DYAPIApi.FetchGetChallengeDataPOST
        challenge_id={props.route?.params?.challenge_id ?? 1}
        user_id={Constants['USER_ID']}
      >
        {({ loading, error, data, refetchGetChallengeData }) => {
          const dailyPracticeSectionData = data;
          if (!dailyPracticeSectionData || loading) {
            return <ActivityIndicator />;
          }

          if (error) {
            return (
              <Text style={{ textAlign: 'center' }}>
                There was a problem fetching this data
              </Text>
            );
          }

          return (
            <>
              <View>
                {/* Heading */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingLeft: 16, paddingRight: 16 },
                    dimensions.width
                  )}
                >
                  <View>
                    <Image
                      style={StyleSheet.applyWidth(
                        { height: 100 },
                        dimensions.width
                      )}
                      resizeMode={'cover'}
                      source={{
                        uri: `${dailyPracticeSectionData?.content?.image}`,
                      }}
                    />
                  </View>
                  <Spacer top={8} right={8} bottom={8} left={8} />
                  {/* Primary */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {dailyPracticeSectionData?.content?.title}
                  </Text>
                  {/* Secondary */}
                  <>
                    {!dailyPracticeSectionData?.content?.content ? null : (
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {dailyPracticeSectionData?.content?.content}
                      </Text>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!Constants[
                        'challengeDetailDataProgressCaption1'
                      ] ? null : (
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Poppins_700Bold',
                              paddingBottom: 5,
                              paddingTop: 10,
                            },
                            dimensions.width
                          )}
                        >
                          {'PROGRESS'}
                        </Text>
                      )}
                    </>
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['DY - Cyan'],
                          fontFamily: 'Poppins_700Bold',
                          marginTop: 5,
                        },
                        dimensions.width
                      )}
                    >
                      {dailyPracticeSectionData?.content?.progress}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 16, paddingRight: 16 },
                  dimensions.width
                )}
              >
                <FlatList
                  renderItem={({ item }) => {
                    const listData = item;
                    return (
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const GetChallengeData =
                                await DYAPIApi.getChallengeDataPOST(Constants, {
                                  challenge_id:
                                    props.route?.params?.challenge_id ?? 1,
                                  user_id: Constants['USER_ID'],
                                });
                              const WistiaMediaIDResult =
                                GetChallengeData?.wistiamediaid;
                              setGlobalVariableValue({
                                key: 'challengewistiamediaid',
                                value: WistiaMediaIDResult,
                              });
                              navigation.navigate('VideoDetailScreen', {
                                challenge_id:
                                  props.route?.params?.challenge_id ?? 1,
                                wistiamediaid: listData?.wistiamediaid,
                                id: listData?.id,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <Surface
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom Color_2'],
                              borderRadius: 5,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: 10,
                              marginTop: 10,
                              minHeight: 40,
                              paddingBottom: 10,
                              paddingLeft: 10,
                              paddingTop: 10,
                            },
                            dimensions.width
                          )}
                          elevation={1}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { width: '65%' },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_700Bold',
                                },
                                dimensions.width
                              )}
                            >
                              {listData?.title}
                            </Text>

                            <Text
                              style={StyleSheet.applyWidth(
                                { color: theme.colors.strong },
                                dimensions.width
                              )}
                            >
                              {'with '}
                              {listData?.author}
                            </Text>

                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingRight: 16,
                                },
                                dimensions.width
                              )}
                            >
                              <View>
                                <Image
                                  style={StyleSheet.applyWidth(
                                    { height: 9, marginTop: 8, width: 15 },
                                    dimensions.width
                                  )}
                                  resizeMode={'cover'}
                                  source={{ uri: `${listData?.watched}` }}
                                />
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Image
                                  style={StyleSheet.applyWidth(
                                    {
                                      height: 12,
                                      marginRight: 4,
                                      marginTop: 5,
                                      width: 13,
                                    },
                                    dimensions.width
                                  )}
                                  resizeMode={'cover'}
                                  source={{ uri: `${listData?.durationIcon}` }}
                                />
                                <Text
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors.strong },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.duration}
                                </Text>
                              </View>
                            </View>
                          </View>

                          <View>
                            <Image
                              style={StyleSheet.applyWidth(
                                { height: 74, width: 130 },
                                dimensions.width
                              )}
                              resizeMode={'cover'}
                              source={{ uri: `${listData?.image}` }}
                            />
                          </View>
                        </Surface>
                      </Touchable>
                    );
                  }}
                  data={dailyPracticeSectionData?.videos}
                  listKey={'bcIwi9GR'}
                  keyExtractor={listData =>
                    listData?.id || listData?.uuid || JSON.stringify(listData)
                  }
                  contentContainerStyle={StyleSheet.applyWidth(
                    { flex: 1 },
                    dimensions.width
                  )}
                  numColumns={1}
                />
              </View>
            </>
          );
        }}
      </DYAPIApi.FetchGetChallengeDataPOST>
      <Spacer top={8} right={8} bottom={8} left={8} />
    </ScreenContainer>
  );
};

export default withTheme(ChallengeDetailScreen);
