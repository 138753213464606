import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DYAPIApi from '../apis/DYAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const ChallengesListScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const myNavigateFunction = Variables => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    navigation.navigate('Survey Start');
    if (Variables.category_id == 1) {
      // alert(Variables.category_id);
      navigation.navigate('SurveyStart');
    }
  };

  const challengeRedirect = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    if (progressbtnstatus === '2') {
      props.navigation.navigate('Terms');
    } else {
      props.navigation.navigate('Terms');
    }
  };

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setShowNav(0);
        const challengesData = await DYAPIApi.newHomePageGET(Constants, {
          user_id: Constants['USER_ID'],
        });
        setGlobalVariableValue({
          key: 'challenge1DataProgress',
          value: '',
        });
        console.log(challengesData);
        if (Constants['AUTHORIZATION_HEADER']) {
          return;
        }
        navigation.navigate('LoginScreen');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [progressbtnstatus, setProgressbtnstatus] = React.useState('');
  const [showNav, setShowNav] = React.useState(false);

  return (
    <ScreenContainer hasSafeArea={true} scrollable={true}>
      {/* Menu */}
      <>
        {!showNav ? null : (
          <Surface
            style={StyleSheet.applyWidth(
              GlobalStyles.SurfaceStyles(theme)['Drawer'],
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  top: '10%',
                },
                dimensions.width
              )}
            >
              {/* Challenges */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ChallengesListScreen', {
                          id: props.route?.params?.id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Ionicons/trophy-outline'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Challenges'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* About */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('AboutScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/infocirlceo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'About'}
                  </Text>
                </View>
              </Touchable>
              {/* Help */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('HelpScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'AntDesign/questioncircleo'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Help'}
                  </Text>
                </View>
              </Touchable>
              {/* FAQ */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FAQScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Octicons/tasklist'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'FAQ'}
                  </Text>
                </View>
              </Touchable>
              {/* Feedback */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('FeedbackScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Feather/message-square'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Feedback'}
                  </Text>
                </View>
              </Touchable>
              {/* Member Dashboard */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('MemberDashboardScreen', {
                          user_id: props.route?.params?.id ?? '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'Feather/settings'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Member Dashboard'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Terms */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('TermsScreen', {
                      user_id: Constants['USER_ID'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 12,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon size={24} name={'Ionicons/ios-document-text-outline'} />
                  <Text
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 18,
                          paddingLeft: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Terms'}
                  </Text>
                </View>
              </Touchable>
              {/* Login */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] === '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Login'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
              {/* Logout */}
              <>
                {!(Constants['AUTHORIZATION_HEADER'] !== '') ? null : (
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'USER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'CHALLENGE_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'EMAIL_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'MEMBER_ID',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: '',
                        });
                        navigation.navigate('LoginScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon size={24} name={'MaterialIcons/logout'} />
                      <Text
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 18,
                              paddingLeft: 8,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Logout'}
                      </Text>
                    </View>
                  </Touchable>
                )}
              </>
            </View>
          </Surface>
        )}
      </>
      <>
        {/* Head Wrap */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: theme.colors.divider,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
              paddingBottom: 15,
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Logo */}
          <View>
            <Touchable>
              <Image
                style={StyleSheet.applyWidth(
                  { height: 42, width: 66 },
                  dimensions.width
                )}
                resizeMode={'cover'}
                source={Images.DyAppLogo}
              />
            </Touchable>
          </View>
          {/* Main Menu */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('HelpScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginRight: 15 },
                dimensions.width
              )}
              color={theme.colors['Custom #101010']}
              icon={'AntDesign/questioncircleo'}
              size={28}
            />
            <Checkbox
              onPress={newCheckboxValue => {
                try {
                  setShowNav(newCheckboxValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              status={showNav}
              uncheckedIcon={'Entypo/menu'}
              color={theme.colors['Custom #101010']}
              uncheckedColor={theme.colors['Custom #101010']}
              checkedIcon={'Feather/x'}
              size={32}
            />
          </View>
        </View>
      </>
      {/* ChallengesWrap */}
      <View>
        <DYAPIApi.FetchNewHomePageGET
          user_id={Constants['USER_ID']}
          onData={fetchData => {
            const handler = async () => {
              try {
                await DYAPIApi.newHomePageGET(Constants, { user_id: 19296 });
                const homeChallenges = fetchData?.videos;
                setGlobalVariableValue({
                  key: 'challenges',
                  value: homeChallenges,
                });
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
        >
          {({ loading, error, data, refetchNewHomePage }) => {
            const fetchData = data;
            if (!fetchData || loading) {
              return <ActivityIndicator />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center' }}>
                  There was a problem fetching this data
                </Text>
              );
            }

            return (
              <>
                {!fetchData?.videos ? null : (
                  <View>
                    {/* Title */}
                    <>
                      {!fetchData?.content?.title ? null : (
                        <View>
                          {/* Heading */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingLeft: 16, paddingRight: 16 },
                              dimensions.width
                            )}
                          >
                            {/* Primary */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {'Challenges'}
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    <Spacer right={0} left={0} top={6} bottom={6} />
                    <FlashList
                      renderItem={({ item }) => {
                        const flashListData = item;
                        return (
                          <>
                            {/* Challenge1 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_2'],
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                },
                                dimensions.width
                              )}
                            >
                              <Surface
                                style={StyleSheet.applyWidth(
                                  { minHeight: 40 },
                                  dimensions.width
                                )}
                                elevation={1}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        alignSelf: 'flex-start',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        overflow: 'hidden',
                                        width: '30%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      style={StyleSheet.applyWidth(
                                        {
                                          height: 100,
                                          minHeight: 100,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${flashListData?.image}`,
                                      }}
                                    />
                                  </View>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'flex-start',
                                        justifyContent: 'space-between',
                                        width: '70%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                          marginLeft: 16,
                                          marginRight: 16,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Name */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'Poppins_600SemiBold',
                                            fontSize: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {flashListData?.title}
                                      </Text>
                                      {/* Name */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 14,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {flashListData?.noofday}
                                      </Text>
                                    </View>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignContent: 'flex-end',
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          marginLeft: 16,
                                          marginRight: 16,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!Constants[
                                          'challenge1DataProgressCaption'
                                        ] ? null : (
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily: 'Poppins_700Bold',
                                                paddingBottom: 5,
                                                paddingTop: 10,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {flashListData?.progressCaption}{' '}
                                          </Text>
                                        )}
                                      </>
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['DY - Cyan'],
                                            fontFamily: 'Poppins_700Bold',
                                            marginTop: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {flashListData?.progress}
                                      </Text>
                                    </View>
                                  </View>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      paddingBottom: 16,
                                      paddingLeft: 16,
                                      paddingRight: 16,
                                      paddingTop: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <>
                                    {!(
                                      flashListData?.btnstatus === 2
                                    ) ? null : (
                                      <Button
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'ChallengeDetailScreen',
                                              {
                                                user_id: Constants['USER_ID'],
                                                survey_status:
                                                  Constants['survey_status'],
                                                category_id:
                                                  flashListData?.categoryId,
                                                challenge_id: flashListData?.id,
                                                progress:
                                                  flashListData?.progress,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['DY - Cyan'],
                                            borderRadius: 8,
                                            color: 'rgb(0,0,0)',
                                            fontFamily: 'Poppins_700Bold',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={`${flashListData?.btnCaption}`}
                                      />
                                    )}
                                  </>
                                  <>
                                    {!(
                                      flashListData?.btnstatus === 1
                                    ) ? null : (
                                      <Button
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              await DYAPIApi.surveyStatusPOST(
                                                Constants,
                                                {
                                                  challenge_id:
                                                    flashListData?.id,
                                                  survey_status:
                                                    Constants['survey_status'],
                                                  user_id: Constants['USER_ID'],
                                                }
                                              );
                                              navigation.navigate(
                                                'SurveyStartMovementScreen',
                                                {
                                                  survey_status:
                                                    Constants['survey_status'],
                                                  category_id: parseInt(
                                                    flashListData?.categoryId,
                                                    10
                                                  ),
                                                  challenge_id: parseInt(
                                                    flashListData?.id,
                                                    10
                                                  ),
                                                  user_id: Constants['USER_ID'],
                                                }
                                              );
                                              console.log(flashListData?.id);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['DY - Cyan'],
                                            borderRadius: 8,
                                            color: 'rgb(0,0,0)',
                                            fontFamily: 'Poppins_700Bold',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={`${flashListData?.btnCaption}`}
                                      />
                                    )}
                                  </>
                                </View>
                              </Surface>
                            </View>
                            <Spacer right={0} left={0} top={6} bottom={6} />
                          </>
                        );
                      }}
                      data={fetchData?.videos}
                      listKey={'SlAIyZ4u'}
                      keyExtractor={flashListData =>
                        flashListData?.id ||
                        flashListData?.uuid ||
                        JSON.stringify(flashListData)
                      }
                      estimatedItemSize={50}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </View>
                )}
              </>
            );
          }}
        </DYAPIApi.FetchNewHomePageGET>
        <Spacer right={0} left={0} top={6} bottom={6} />
      </View>

      <View>
        <Text
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {null}
        </Text>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ChallengesListScreen);
