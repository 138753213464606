import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useWindowDimensions } from 'react-native';

const WelcomeScreen = props => {
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (Constants['AUTHORIZATION_HEADER'] === '') {
        return;
      }
      navigation.navigate('RootNavigator');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [showNav, setShowNav] = React.useState(false);

  return (
    <ScreenContainer
      scrollable={true}
      hasTopSafeArea={false}
      hasSafeArea={true}
    />
  );
};

export default withTheme(WelcomeScreen);
